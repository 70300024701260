<div class="h-100">
    <div *ngFor="let claim of identity?.claims; index as i"
         class="justify-content-center" (click)="openCloseClaim(claim)">
        <div *ngIf="!loading" class="row text-dark">
            <div class="mb-3 pointer {{i % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary'}}"
                 [id]="'claim-row-'+i"
                 [attr.data-bs-target]="'#claims-claim-'+i"
                 aria-expanded="false"
                 data-bs-toggle="collapse">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center min-width-220">
                        <i class="fa fa-fw primary-blue {{claim.type | toClaimScopeIcon}}"></i>
                        <div class="ms-3 text-start truncate-text">{{ claim.type |  toReadableString }}: {{ claim.description }}</div>
                        <i class="ms-2 fa fa-fw primary-blue {{claim.visibilityScope | toVisibilityScopeIcon}}"></i>
                    </div>
                    <app-action-button [useSpinner]="true"
                                       [loading]="claimLoading.get(claim.cid!) || false"
                                       [showPrimary]="open.get(claim.cid!) || false"
                                       [showSecondary]="!open.get(claim.cid!) || false"
                                       [btnClass]="'btn-info'"
                                       [primaryIconClass]="'fa fa-chevron-up'"
                                       [secondaryIconClass]="'fa fa-chevron-down'">
                    </app-action-button>
                </div>
            </div>
        </div>
        <div class="collapse" [id]="'claims-claim-'+i">
            <app-claim [myProfile]="false"
                       [identity]="identity"
                       [claim]="claim"></app-claim>
        </div>
    </div>
    <div *ngIf="identity?.claims?.length == 0"
         class="mt-3 text-center greyed-out ps-3">No claims visible to you
    </div>
</div>
