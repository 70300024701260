<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
        <div class="mt-4">
            <app-logo
                    [classes]="'mx-auto d-block'"
                    [height]="'100'"
                    [image]="'/doatoa-logo-full.svg'"
                    [width]="'150'">
            </app-logo>
        </div>
        <div class="col-auto text-center max-width-300 p-3">
            <form (submit)="process()" class="d-flex justify-content-center w-100">
                <div class="p-3 max-width-300">
                    <div class="mb-2">
                        <input [(ngModel)]="email"
                               [ngClass]="{ 'is-valid': isValidEmail(email) }"
                               id="email"
                               name="email"
                               class="form-control rounded-5 height-40"
                               placeholder="E-mail address"
                               type="email"
                               autocapitalize="off"
                               autocomplete="new-email">
                    </div>
                    <div class="mb-2">
                        <app-generic-button
                                id="sign-in"
                                (click)="method = 'doatoa'"
                                [type]="'submit'"
                                [disabled]="loading || !email || !isValidEmail(email)"
                                [loading]="loading"
                                [buttonText]="'Next'"
                                [btnClass]="'btn-info'"
                                [primaryIcon]="'fa-solid fa-right-to-bracket'">
                        </app-generic-button>

                    </div>

                    <!--                        <app-generic-button-->
                    <!--                                id="sign-in-trezor"-->
                    <!--                                class="mb-2"-->
                    <!--                                (click)="method = 'trezor'"-->
                    <!--                                [type]="'submit'"-->
                    <!--                                [disabled]="loading || !email"-->
                    <!--                                [loading]="loading"-->
                    <!--                                [buttonText]="'Sign in with Trezor'"-->
                    <!--                                [icon]="'fa-solid fa-right-to-bracket'">-->
                    <!--                        </app-generic-button>-->

                </div>
            </form>

            <app-error-modal
                    [showModal]="errorOccurred"
                    [extraClasses]="'m-3'"
                    [message]="errorMessage">
            </app-error-modal>
        </div>

        <ng-container *ngIf="loading && !errorOccurred">
            <app-spinner [loading]="true"
                         class="m-auto mt-5"
                         [style]="'width: 183px; height: 183px;'"></app-spinner>
            <div class="mt-3">Signing in...</div>
        </ng-container>

        <app-error-modal [showModal]="errorOccurred"></app-error-modal>
    </div>
</div>