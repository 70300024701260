<nav class="bg-white p-0" *ngIf="showMenu">
    <div class="d-flex vw-100">
        <div ariaCurrentWhenActive='page'
             class="btn nav-item height-90 p-4 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/settings"
             routerLinkActive="active">
            <i class="fa fa-gears fa-2x"></i>
        </div>
        <div ariaCurrentWhenActive='page'
             class="btn nav-item height-90 p-4 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/my-identity"
             routerLinkActive="active">
            <i class="fa fa-user fa-2x"></i>
        </div>
        <div ariaCurrentWhenActive='page'
             class="btn nav-item height-90 p-4 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/identities"
             routerLinkActive="active">
            <i class="fa fa-users fa-2x"></i>
        </div>
    </div>
</nav>