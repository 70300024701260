import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {SafeResourceUrl} from "@angular/platform-browser";
import * as uuid from "uuid";
import {DataService} from "../../services/data.service";
import {IdentityView} from "../../services/model/identity-view";
import {ClaimView} from "../../services/model/claim-reference-view";
import {AuthorisedComponent} from "../common/authorised-component";
import {SignOutService} from "../../services/signout-service";
import {LoadingPlaceholderComponent} from "../common/loading-placeholder/loading-placeholder.component";
import {NgIf} from "@angular/common";
import {NotificationsComponent} from "./notifications/notifications.component";
import {IdentitySummaryComponent} from "./identity-summary/identity-summary.component";
import {MyClaimsComponent} from "./my-claims/my-claims.component";
import {MySubscriptionsComponent} from "./my-subscriptions/my-subscriptions.component";
import {CryptoWalletService} from "../../services/crypto-wallet.service";


@Component({
  selector: 'app-my-identity',
  templateUrl: './my-identity.component.html',
  standalone: true,
  imports: [
    LoadingPlaceholderComponent,
    NgIf,
    NotificationsComponent,
    IdentitySummaryComponent,
    MyClaimsComponent,
    MySubscriptionsComponent
  ],
  styleUrls: ['./my-identity.component.scss']
})
export class MyIdentityComponent extends AuthorisedComponent implements OnInit {
  
  @Output() claimViewEmitter: EventEmitter<ClaimView> = new EventEmitter<ClaimView>()
  protected myProfileId: string = 'my-claims-reference-' + uuid.v4()
  protected loading: boolean = !this.dataService.has('identity')
  protected did?: string = undefined
  protected queryParams?: any = undefined
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected flipOpenForRequestsLoading: boolean = false
  protected imageToUpload?: Promise<ArrayBuffer> = undefined
  protected imagePath?: SafeResourceUrl = undefined
  protected identity?: IdentityView
  
  constructor(dataService: DataService, signOutService: SignOutService, cryptoWalletService: CryptoWalletService) {
    super(dataService, signOutService, cryptoWalletService);
  }
  
  ngOnInit() {
    this.dataService.continuousPublications<boolean>('dataLoading').subscribe(dataLoading => {
      this.loading = dataLoading
    })
    this.dataService.continuousChanges<IdentityView>('identity').subscribe(identity => {
      this.identity = identity
    })
  }
  
  //
  // protected removeRequest($event: SubscriptionView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('claims') as IdentityView
  //     currentIdentity?.my-subscriptions!.forEach((element, index) => {
  //         if (currentIdentity?.my-subscriptions![index].id == $event.id) {
  //             currentIdentity?.my-subscriptions!.splice(index, 1);
  //         }
  //     });
  //     this.dataService.publish('claims', currentIdentity)
  // }
  
  // protected updateRequest($event: SubscriptionView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('claims') as IdentityView
  //     currentIdentity?.my-subscriptions!.forEach((element, index) => {
  //         if (currentIdentity?.my-subscriptions![index].id == $event.id) {
  //             currentIdentity!.my-subscriptions![index] = $event
  //         }
  //     });
  //     this.dataService.publish('claims', currentIdentity)
  // }
  
  // protected updateMyClaims(claimView: ClaimView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('claims') as IdentityView
  //     currentIdentity?.claims?.push(claimView)
  //     this.dataService.publish('claims', currentIdentity)
  // }
  
}
