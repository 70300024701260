<div class="card p-3 mb-3">
    <h3>For developers</h3>
    <div class="row justify-content-center">
        <img class="col-auto" alt="developers" ngSrc="/developers.png" height="300" width="300">
        <app-generic-button routerLink="/developers-console"
                            [btnClass]="'btn-info'"
                            [buttonText]="'Developers console'"
                            [primaryIcon]="'fa-solid fa-code'">
        </app-generic-button>
    </div>
</div>