import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";

import {LoadingPlaceholderComponent} from "../loading-placeholder/loading-placeholder.component";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {CacheService} from "../../../services/cache-service";
import {DataService} from "../../../services/data.service";
import {ClaimView} from "../../../services/model/claim-reference-view";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";

@Component({
  selector: 'app-delete-claim-modal',
  templateUrl: './delete-claim-modal.component.html',
  standalone: true,
  imports: [
    LoadingPlaceholderComponent,
    NgIf,
    FormsModule,
    GenericButtonComponent
  ],
  styleUrl: './delete-claim-modal.component.scss'
})
export class DeleteClaimModalComponent implements OnInit {
  
  @Output() claimIdToFetchEmitter = new EventEmitter<string>()
  @Output() deletedClaimId = new EventEmitter<string>();
  protected claimToDelete?: ClaimView
  protected deleted: boolean = false
  protected copyClicked: boolean = false
  protected deleteClaimClicked: boolean = false
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected claimType: string = ''
  protected classes: string = ''
  protected errorMessage: string = ''
  protected scopes: Array<string> = []
  protected scope?: string = undefined
  
  constructor(private claimControllerService: ClaimControllerService, private cacheService: CacheService, private dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.loading.set('component', true)
    this.dataService.continuousChanges<ClaimView>('claimToDelete')
      .subscribe({
        next: claimToDelete => {
          this.claimToDelete = claimToDelete
          this.loading.set('component', false)
        }
      })
  }
  
  deleteClaim() {
    this.cacheService.bypassCache = true
    if (this.claimToDelete != undefined && this.claimType == this.claimToDelete.type) {
      this.loading.set(this.claimToDelete.cid!!, true)
      this.claimControllerService.deleteClaim(this.claimToDelete.cid!)
        .subscribe({
          next: (response) => {
            this.cacheService.clear()
            if (this.claimType == 'avatar') {
              this.dataService.remove('avatar')
              this.dataService.remove('avatarId')
            }
            this.loading.set(this.claimToDelete!.cid!!, false)
            this.deleteClaimClicked = false
            this.deletedClaimId.emit(this.claimToDelete!.cid)
            this.claimType = ''
            this.deleted = true;
          },
          error: (error) => {
            this.loading.set(this.claimToDelete!.cid!!, false)
            this.deleteClaimClicked = false
            this.claimType = ''
            if (error instanceof HttpErrorResponse) {
              this.errorOccurred = true
            }
          }
        });
    }
  }
  
  nameMatches() {
    return this.claimToDelete?.type === this.claimType;
  }
  
  copyText() {
    this.copyClicked = true
  }
  
  componentLoading() {
    return this.loading.get('component') || false;
  }
}
