import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {LogoComponent} from "../../common/logo/logo.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {JsonPatchPayload} from "../../../services/model/json-patch-payload";


@Component({
    selector: 'app-activate-claims',
    templateUrl: './activate-user-init.component.html',
    standalone: true,
    imports: [
        LogoComponent,
        SpinnerComponent
    ],
    styleUrl: './activate-user-init.component.scss'
})
export class ActivateUserInitComponent implements OnInit {

    loading: boolean = false
    errorOccurred: boolean = false

    constructor(private userCommandControllerService: IdentityControllerService, private activatedRoute: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (!!p.params.token) {
                this.activateUser(p.params.token)
            }
        });
    }

    private activateUser(token: string) {
        this.loading = true
        const jsonPatchPayload: JsonPatchPayload = {
            op: "remove",
            path: "/aspects",
            value: "deactivated"
        }
        this.userCommandControllerService.patchIdentity([jsonPatchPayload], token)
            .subscribe({
                next: (response) => {
                    this.loading = false
                    this.router.navigate(['/sign-in'])
                },
                error: (error) => {
                    this.loading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }
}
