<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
        <div class="mt-4">
            <app-logo
                    [classes]="'mx-auto d-block'"
                    [height]="'100'"
                    [image]="'/doatoa-logo-full.svg'"
                    [width]="'150'">
            </app-logo>
        </div>
        <div class="col-auto text-center max-width-300 p-3">
            <div class="mb-2">
                <app-generic-button [buttonText]="'Create account'"
                                    [btnClass]="'btn-info'"
                                    [primaryIcon]="'fa-solid fa-plus'"
                                    [loading]="loading"
                                    [disabled]="email == undefined || !isValidEmail(email)"
                                    (click)="onNext()"></app-generic-button>
            </div>
            <div class="mb-2">
                <app-generic-button [buttonText]="'Restore account'"
                                    [btnClass]="'btn-info'"
                                    [loading]="loading"
                                    [primaryIcon]="'fa-solid fa-wallet'"
                                    routerLink="/recover-keys"></app-generic-button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-300 text-center">
            <app-error-modal [showModal]="errorOccurred()"
                             [extraClasses]="'m-3'"></app-error-modal>
        </div>
    </div>
</div>