<app-new-subscription-modal (submittedRequest)="addSubscription($event)"
                            [customId]="newRequestIdentifier"
                            [topicOwner]="identity.did"
                            [title]="'Request subscription'"></app-new-subscription-modal>
<app-new-claim-modal [proposed]="true"
                     [subject]="identity.did"
                     [customId]="newClaimIdentifier"
                     (claimMade)="addClaim($event)"></app-new-claim-modal>
<app-export-modal [subject]="identity.did" [customId]="exportIdentifier"></app-export-modal>
<div class="d-flex justify-content-center card p-3 w-100 overflow-x-hidden overflow-y-auto" [ngClass]="{'margin-bottom-200px': !dataService.isLargeViewPort()}">
    <div>
        <div class="pointer text-dark"
             [attr.aria-controls]="'identity-'+identity.hash"
             [attr.data-bs-target]="'#identity-'+identity.hash"
             aria-expanded="false"
             data-bs-toggle="collapse">
            <!--            <img *ngIf="filePath == '' && !loading && !loadingAvatar"-->
            <!--                 alt="avatar"-->
            <!--                 class="col-md float-start"-->
            <!--                 height="100"-->
            <!--                 ngSrc="{{resolveSource()}}"-->
            <!--                 width="100">-->
            <!--            <div *ngIf="filePath != '' && !loading && !loadingAvatar"-->
            <!--                 [ngClass]="{'box-shadow-avatar': !!filePath}"-->
            <!--                 [ngStyle]="{backgroundImage: 'url(' + filePath + ')', backgroundSize: 'cover', width: '100px', height: '100px', border: '3px solid rgb(58,134,255)'}"-->
            <!--                 class="float-start center-container rounded-circle">-->
            <!--            </div>-->
            <!--            <app-spinner [loading]="loadingAvatar || loading"-->
            <!--                         [spinnerClasses]="'col-md float-start'"-->
            <!--                         [style]="'width: 100px; height: 100px;'"></app-spinner>-->
            <div class="p-3 justify-content-center">
                <h5 class="truncate-text">
                    <i class="fa-solid fa-id-card secondary-blue me-2 max-height-50"></i>
                    DID: {{ identity.did }}
                </h5>
                <h5 class="truncate-text">
                    <i class="fa-solid fa-fingerprint secondary-blue me-2 max-height-50"></i>
                    Hash: {{ identity.hash || 'none' }}
                </h5>
                <h5 class="truncate-text">
                    <i class="fa-solid fa-mask secondary-blue me-2 max-height-50"></i>
                    Alias: {{ identity.alias || 'none' }}
                </h5>
                <h5 class="truncate-text">
                    <i class="fa {{ identity.visibilityScope | toVisibilityScopeIcon }} secondary-blue me-2 max-height-50"></i>
                    Visibility: {{ identity.visibilityScope | toReadableString }}
                </h5>
            </div>
        </div>
    </div>
    <div [id]="'identity-'+identity.hash" class="collapse">
        <div class="row text-dark p-3">
            <div class="p-3 card min-width-300">
                <div class="d-flex align-items-center justify-content-end mb-3">
                    <div class="h5-container">
                        <h5 class="w-100 text-center mt-2 position-absolute start-50 translate-middle">Claims</h5>
                    </div>
                    <app-action-button
                            class="me-1"
                            [useSpinner]="true"
                            [disabled]="identity.visibilityScope != 'public' || subscribedByCurrentIdentity"
                            [showPrimary]="true"
                            [btnClass]="'btn-info'"
                            [primaryIconClass]="'fa-solid fa-bell'"
                            [attr.data-bs-toggle]="'modal'"
                            [attr.data-bs-target]="'#'+newRequestIdentifier">
                    </app-action-button>
                    <app-action-button
                            [useSpinner]="true"
                            [disabled]="noClaimsMade() || !subscribedByCurrentIdentity"
                            [showPrimary]="true"
                            [btnClass]="'btn-info'"
                            [primaryIconClass]="'fa fa-cloud-arrow-down'"
                            [attr.data-bs-target]="'#'+exportIdentifier"
                            [attr.data-bs-toggle]="noClaimsMade()? null : 'modal'">
                    </app-action-button>
                </div>
                <app-claims [identity]="identity"></app-claims>
            </div>
        </div>
    </div>
</div>

