import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {DataService} from "../../../services/data.service";
import {IdentityView} from "../../../services/model/identity-view";
import {Notification} from "../../../services/model/notification";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    NgIf,
    NgForOf
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
  
  protected loading: boolean = false
  protected notifications: Array<Notification> = [];
  
  constructor(private dataService: DataService, private cryptoWalletService: CryptoWalletService,) {
  }
  
  ngOnInit() {
    this.loading = true;
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        this.notifications.push(...identity.subscriptions?.flatMap(it => it.events || []) || [])
      }
    })
  }
  
}
