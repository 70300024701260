import {Component} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../../../services/data.service";
import {RedirectService} from "../../../../services/redirect-service";
import {GenericButtonComponent} from "../../../common/generic-button/generic-button.component";
import {RouterLink} from "@angular/router";
import {IdentityControllerService} from "../../../../services/api/identity-controller.service";
import {SignOutService} from "../../../../services/signout-service";
import {CryptoWalletService} from "../../../../services/crypto-wallet.service";


@Component({
  selector: 'app-close-account',
  templateUrl: './close-account.component.html',
  standalone: true,
  imports: [
    GenericButtonComponent,
    RouterLink
  ],
  styleUrls: ['./close-account.component.scss']
})
export class CloseAccountComponent {
  
  loading: boolean = false
  closeAccountAccepted: boolean = false
  errorOccurred: boolean = false
  errorMessage: string = ''
  
  constructor(protected dataService: DataService, private signOutService: SignOutService, private cryptoWalletService: CryptoWalletService, private identityControllerService: IdentityControllerService) {
  }
  
  flipAcceptCloseAccount() {
    this.closeAccountAccepted = !this.closeAccountAccepted
  }
  
  closeAccount() {
    if (this.closeAccountAccepted) {
      this.loading = true
      this.cryptoWalletService.getStored<string>('did')
        .then(did => {
          this.identityControllerService.closeAccount(did)
            .subscribe({
              next: (response) => {
                this.signOutService.signOut()
              },
              error: (error) => {
                this.loading = false
                if (error instanceof HttpErrorResponse) {
                  this.errorOccurred = true
                }
              }
            })
        })
    }
  }
}
