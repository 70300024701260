<button class="btn {{btnClass}} d-flex justify-content-between align-items-center w-100 {{disabled ? 'disabled' : ''}} {{active ? 'active' : ''}}"
        role="button"
        [attr.data-bs-dismiss]="dataBsDismiss"
        [attr.type]="type"
        aria-pressed="true">
    <div class="d-flex justify-content-between align-items-center">
        <div *ngIf="showPrimaryIcon && !loading" class="d-flex justify-content-between align-items-center">
            <img *ngIf="primaryIcon.includes('.')" ngSrc="{{primaryIcon}}" height="{{height}}" width="{{width}}">
            <i *ngIf="!primaryIcon.includes('.')" class="ms-2 {{buttonText == '' ? ''  : 'me-2'}} {{ primaryIcon }}"></i>
        </div>
        <div *ngIf="showSecondaryIcon && !loading" class="d-flex justify-content-between align-items-center">
            <img *ngIf="secondaryIcon.includes('.')" ngSrc="{{buttonText == '' ? ''  : 'me-2'}} {{secondaryIcon}}" height="{{height}}" width="{{width}}">
            <i *ngIf="!secondaryIcon.includes('.')" class="ms-2 {{buttonText == '' ? ''  : 'me-2'}} {{ secondaryIcon }}"></i>
        </div>
        <app-spinner class="m-auto" [loading]="loading"></app-spinner>
    </div>
    <span class="text-center text-truncate {{(primaryIcon == '' || alwaysShowText) ? '' : 'd-none'}} d-md-block flex-grow-1">{{ buttonText }}</span>
</button>