import {Component, OnInit} from '@angular/core';
import {LogoComponent} from "../../common/logo/logo.component";
import {environment} from "../../../environments/environment";
import {DataService} from "../../../services/data.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-authentication-method',
  standalone: true,
  imports: [
    LogoComponent
  ],
  templateUrl: './authentication-method.component.html',
  styleUrl: './authentication-method.component.scss'
})
export class AuthenticationMethodComponent implements OnInit {
  
  protected chosenAuthenticationMethod? = 'doatoa'
  protected methods: string[] = environment.authenticationMethods
  
  constructor(private dataService: DataService, private cryptoWalletService: CryptoWalletService, private router: Router) {
  }
  
  ngOnInit() {
    this.initialiseKeyStore()
  }
  
  flipMethod(method: string) {
    if (this.chosenAuthenticationMethod == method) {
      this.chosenAuthenticationMethod = undefined
    } else {
      this.chosenAuthenticationMethod = method
    }
  }
  
  async initialiseKeyStore() {
    this.dataService.publish('authenticationMethod', this.chosenAuthenticationMethod);
    const email = await this.cryptoWalletService.getStored<string>('email')
    const mnemonic = await this.cryptoWalletService.initialise(email!, this.chosenAuthenticationMethod!)
    this.dataService.publish('mnemonic', mnemonic)
    this.router.navigate(['sign-up/mnemonic-phrase'])
  }
}
