import {Component} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-developers',
  standalone: true,
  templateUrl: './developers.component.html',
  imports: [
    GenericButtonComponent,
    NgOptimizedImage,
    RouterLink
  ],
  styleUrl: './developers.component.scss'
})
export class DevelopersComponent {
  
  loading: boolean = false
  errorOccurred: boolean = false
  errorMessage: string = ''
}