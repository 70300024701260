import {Component, Input} from '@angular/core';
import {SpinnerComponent} from "../spinner/spinner.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-action-button',
  standalone: true,
  templateUrl: './action-button.component.html',
  imports: [
    SpinnerComponent,
    NgIf
  ],
  styleUrl: './action-button.component.scss'
})
export class ActionButtonComponent {

  @Input() disabled: boolean = false
  @Input() useSpinner: boolean = false
  @Input() type: string = ''
  @Input() loading: boolean = false
  @Input() showPrimary: boolean = false
  @Input() showSecondary: boolean = false
  @Input() inert: boolean = false
  @Input() primaryIconClass: string = ''
  @Input() secondaryIconClass: string = ''
  @Input() btnClass: string = 'btn-info'
}
