<div class="card p-2 mb-3">
    <div class="">


        <div class="p-3 justify-content-center">
            <h5 class="truncate-text pointer" (click)="clipboardService.copyToClipboard(identity!.did!)">
                <i class="fa-solid fa-id-card secondary-blue me-2 max-height-50"></i>
                DID: {{ identity!.did }}
            </h5>
            <h5 class="truncate-text pointer" (click)="clipboardService.copyToClipboard(identity!.hash!)">
                <i class="fa-solid fa-fingerprint secondary-blue me-2 max-height-50"></i>
                Hash: {{ identity!.hash || 'none' }}
            </h5>
            <h5 class="truncate-text pointer" (click)="clipboardService.copyToClipboard(identity!.alias || 'none')">
                <i class="fa-solid fa-mask secondary-blue me-2 max-height-50"></i>
                Alias: {{ identity!.alias || 'none' }}
            </h5>
            <h5 class="truncate-text pointer" *ngIf="identity">
                <i class="fa {{ identity.visibilityScope | toVisibilityScopeIcon }} secondary-blue me-2 max-height-50"></i>
                Visibility: {{ identity.visibilityScope | toReadableString }}
            </h5>
        </div>


        <div class="d-flex justify-content-center" *ngIf="clipboardService.showCopyConfirmation">
            <div class="position-fixed text-center primary-blue">(Copied to clipboard)</div>
        </div>
        <div class="d-flex justify-content-end">
            <div class="d-flex border rounded-5 p-1 secondary-blue">
                <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                   (click)="alterVisibility(visibilityScope)"
                                   [btnClass]="identity?.visibilityScope == visibilityScope ? 'btn-info' : 'btn-info-disabled'"
                                   [loading]="loadingMap.get(visibilityScope) || false"
                                   [useSpinner]="true"
                                   [showPrimary]="identity?.visibilityScope == visibilityScope"
                                   [showSecondary]="identity?.visibilityScope != visibilityScope"
                                   [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                   [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                   class="{{last ? 'ms-1' : first ? 'settings-1' : 'ms-1 settings-1'}} ">
                </app-action-button>
            </div>
<!--            <div class="d-flex ms-1 pt-1 pb-1 secondary-blue">-->
<!--                <app-action-button-->
<!--                        [showPrimary]="true"-->
<!--                        [useSpinner]="true"-->
<!--                        [loading]="introductionUpdateLoading"-->
<!--                        [btnClass]="'btn-info'"-->
<!--                        [primaryIconClass]="'fa fa-pencil'"-->
<!--                        data-bs-target="#introductionModal"-->
<!--                        data-bs-toggle="modal">-->
<!--                </app-action-button>-->
<!--            </div>-->
        </div>
    </div>
</div>

<!--<div aria-hidden="true"-->
<!--     aria-labelledby="introductionModalLabel"-->
<!--     class="modal fade"-->
<!--     id="introductionModal"-->
<!--     role="dialog"-->
<!--     tabindex="-1">-->
<!--    <div class="modal-dialog"-->
<!--         role="document">-->
<!--        <div class="modal-content">-->
<!--            <div class="modal-header">-->
<!--                <h5 class="modal-title"-->
<!--                >What do you wish to share about yourself?</h5>-->
<!--                <button aria-label="Close"-->
<!--                        class="btn-close float-end m-1"-->
<!--                        data-bs-dismiss="modal"-->
<!--                        type="button">-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="modal-body">-->
<!--                <textarea (reset)="submitIntroduction()"-->
<!--                          (input)="onIntroductionInput($event)"-->
<!--                          [(ngModel)]="introduction"-->
<!--                          class="form-control rounded-1"-->
<!--                          id="summaryText"-->
<!--                          name="value"-->
<!--                          [value]="identity?.introduction"-->
<!--                          placeholder="Value"-->
<!--                          required>-->
<!--                </textarea>-->
<!--            </div>-->
<!--            <div class="modal-footer">-->
<!--                <button (click)="submitIntroduction()"-->
<!--                        class="btn btn-info {{introduction == '' ? 'disabled' : ''}}"-->
<!--                        data-bs-dismiss="modal"-->
<!--                        type="button">Submit-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="mb-3">-->
<!--                <span>Characters remaining: {{ remainingCharacters }}</span>-->
<!--            </div>-->
<!--            <app-error-modal [useTimout]="false"-->
<!--                             [showModal]="remainingCharacters < 0"-->
<!--                             [message]="'Please be less wordy...'"></app-error-modal>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->