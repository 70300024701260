<div class="vw-100 vh-100-offset-190px pt-3">
    <div class="container"
         infiniteScroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="0"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScrolledDown()">
            <div *ngFor="let identity of identities; index as i" class="mb-3">
                <app-identity [index]="i"
                              [identity]="identity"></app-identity>
            </div>
            <div *ngIf="identities == [] || identities?.length === 0"
                 class="d-flex justify-content-center greyed-out m-3">No matching identities
            </div>
            <app-spinner [loading]="loading && identities.length > 0"
                         class="m-auto mt-5"
                         [style]="'width: 100px; height: 100px;'"></app-spinner>
    </div>
    <div [ngClass]="{'margin-bottom-90px': !dataService.isLargeViewPort()}"
         class="position-fixed w-100 bottom-0 justify-content-center p-3">
            <div class="mb-2">
                <input [(ngModel)]="didHashAlias"
                       aria-label="did"
                       autocapitalize="off"
                       class="form-control rounded-5 height-40"
                       placeholder="did, hash or alias"
                       type="search">
            </div>
<!--            <div class="mb-2">-->
<!--                <select (change)="setStatusFilter($event)"-->
<!--                        class="form-select rounded-5 height-40"-->
<!--                        id="requestStatusSelect"-->
<!--                        name="type">-->
<!--                    <option [selected]="defaultSelected" value="">{{ defaultRequestMessage }}-->
<!--                    </option>-->
<!--                    <option *ngFor="let status of ['PENDING', 'APPROVED', 'DENIED']"-->
<!--                            [value]="status.toLowerCase()">Status: {{ status.toLowerCase() }}-->
<!--                    </option>-->
<!--                </select>-->
<!--            </div>-->
            <div class="row">
                <div class="col-6 pe-1">
                    <app-generic-button
                            (click)="findIdentities()"
                            [disabled]="loading"
                            [buttonText]="'Search'"
                            [loading]="loading"
                            [primaryIcon]="'fa-solid fa-magnifying-glass'"
                            [type]="'submit'">
                    </app-generic-button>
                </div>
                <div class="col-6 ps-0">
                    <app-generic-button
                            (click)="reset()"
                            [disabled]="loading"
                            [buttonText]="'Reset'"
                            [loading]="loading"
                            [primaryIcon]="'fa-solid fa-rotate'">
                    </app-generic-button>
                </div>
        </div>
    </div>
</div>