import {Component, EventEmitter, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {CacheService} from "../../services/cache-service";
import {DataService} from "../../services/data.service";
import {IdentityView} from "../../services/model/identity-view";
import {IdentityControllerService} from "../../services/api/identity-controller.service";
import {IdentityComponent} from "./identity/identity.component";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {CryptoWalletService} from "../../services/crypto-wallet.service";

@Component({
    selector: 'app-identities',
    templateUrl: './identities.component.html',
    standalone: true,
    imports: [
        IdentityComponent,
        InfiniteScrollDirective,
        SpinnerComponent,
        NgClass,
        FormsModule,
        GenericButtonComponent,
        NgIf,
        NgForOf
    ],
    styleUrls: ['./identities.component.scss']
})
export class IdentitiesComponent {

    protected loading: boolean = false
    protected defaultSelected: boolean = false
    @Output() protected publicProfilesLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    protected claimTypes: Array<string> = []
    protected identities: Array<IdentityView> = []
    protected didHashAlias?: string = undefined
    protected defaultRequestMessage = 'No subscription status filter'
    protected requestStatus?: string = this.defaultRequestMessage
    protected errorOccurred: boolean = false
    protected errorMessage: string = ''
    protected currentPage: number = 0
    protected pageSize: number = 10
    protected counter: number = 1

    constructor(private cacheService: CacheService, protected dataService: DataService, private identityControllerService: IdentityControllerService,private cryptoWalletService: CryptoWalletService, ) {
    }

    reset() {
        this.defaultSelected = true
        this.identities = []
        this.requestStatus = this.defaultRequestMessage
        this.didHashAlias = undefined
        this.pageSize = 10
        this.currentPage = 0
    }

    findIdentities() {
        this.loading = true
        this.identities = []
        this.publicProfilesLoading.emit(this.loading)
        this.cacheService.bypassCache = true
        const queriedStatus = this.requestStatus == this.defaultRequestMessage ? undefined : this.requestStatus

        this.identityControllerService.findIdentities(this.didHashAlias, queriedStatus, this.currentPage, this.pageSize)
            .subscribe({
                next: async (identities) => {
                    const currentDid = await this.cryptoWalletService.getStored<string>('did')
                    this.identities.push(...identities.filter(i => i.did != currentDid))
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                },
                error: (error) => {
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    onScrolledDown() {
        this.currentPage++
        this.findIdentities();
    }

    setStatusFilter($event: any) {
        this.requestStatus = $event.target.value
    }
}
