import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractHttpService} from "./abstract-http-service";


@Injectable({
  providedIn: 'root'
})
export class ApiStatusControllerService extends AbstractHttpService {
  
  checkApiStatus(): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    let localVarPath = `/public/api-status/health`;
    return this.get(`${this.configuration.basePath}${localVarPath}`, localVarHeaders)
  }
  
  
}
