import {Routes} from '@angular/router';
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {ActivateUserInitComponent} from "./components/landing/activate/activate-user-init.component";

import {SignUpComponent} from "./components/sign-up/sign-up.component";

import {RegisteredLandingComponent} from "./components/landing/register/registered-landing.component";
import {MaintenanceComponent} from "./components/landing/maintenance/maintenance.component";
import {SuccessLandingComponent} from "./components/landing/success/success-landing.component";
import {SettingsComponent} from "./components/settings/settings.component";

import {MyIdentityComponent} from "./components/my-identity/my-identity.component";
import {IdentitiesComponent} from "./components/identities/identities.component";
import {SignOutComponent} from "./components/sign-out/sign-out.component";
import {
    AuthenticationMethodComponent
} from "./components/sign-up/authentication-method/authentication-method.component";
import {MnemonicPhraseComponent} from "./components/sign-up/mnemonic-phrase/mnemonic-phrase.component";
import {RestoreKeysComponent} from "./components/sign-up/restore-keys/restore-keys.component";
import {ErrorComponent} from "./components/common/error/error.component";
import {
    DeveloperConsoleComponent
} from "./components/settings/developers/developers-console/developer-console.component";
import {CloseAccountComponent} from "./components/settings/account/close-account/close-account.component";

export const routes: Routes = [
    // {path: '', redirectTo: '/under-construction', pathMatch: 'full'},
    {path: '', redirectTo: '/my-identity', pathMatch: 'full'},
    {path: 'settings', component: SettingsComponent},
    {path: 'my-identity', component: MyIdentityComponent},
    {path: 'identities', component: IdentitiesComponent},
  
    {path: 'sign-in', component: SignInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'recover-keys', component: RestoreKeysComponent},
    {path: 'sign-up/authentication-methods', component: AuthenticationMethodComponent},
    {path: 'sign-up/mnemonic-phrase', component: MnemonicPhraseComponent},
    {path: 'sign-out', component: SignOutComponent},
  
    {path: 'activate', component: ActivateUserInitComponent},
    {path: 'registered-landing', component: RegisteredLandingComponent},
    {path: 'maintenance', component: MaintenanceComponent},
    {path: 'success-landing', component: SuccessLandingComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'developers-console', component: DeveloperConsoleComponent},
    {path: 'close-account', component: CloseAccountComponent},

    // {path: 'sign-up/payment', component: PaymentComponent},
];