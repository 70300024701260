import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractHttpService} from "./abstract-http-service";
import {FactView} from "../model/fact-view";
import {IpfsStatus} from "../model/ipfs-status";

@Injectable({
  providedIn: 'root'
})
export class IpfsService extends AbstractHttpService {
  
  public getStatus(): Observable<IpfsStatus> {
    let localVarHeaders = this.defaultHeaders;
    return this.get<IpfsStatus>(`https://pinata.statuspage.io/api/v2/status.json`,localVarHeaders)
  }
  
  public getFact(hash?: string): Observable<FactView> {
    let localVarHeaders = this.defaultHeaders;
    return this.get<FactView>(`https://ipfs.io/ipfs/${hash}`,localVarHeaders)
    // return this.get<FactView>(`${this.configuration.basePath}/ipfs/${hash}`, localVarHeaders)
  }
}
