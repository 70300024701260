import {Component, Input} from '@angular/core';

import {ToReadableSubscriptionStatusPipe} from "../pipes/ToReadableRequestStatusPipe";
import {ToDateTimeFormatPipe} from "../pipes/ToDateTimeFormatPipe";
import {EventView} from "../../../services/model/event-view";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  standalone: true,
  imports: [
    ToReadableSubscriptionStatusPipe,
    ToDateTimeFormatPipe
  ],
  styleUrl: './event.component.scss'
})
export class EventComponent {

  @Input() event: EventView = {}
}
