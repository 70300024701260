<div class="card p-3 mb-4">
    <h4>API Status</h4>
    <div>
        <div>API (global):
            <span *ngIf="!loading"
                  class="{{apiStatus == 'UP' ? 'secondary-blue' : 'primary-danger'}}">{{ apiStatus }}</span>
        </div>
        <div>Database:
            <span *ngIf="!loading"
                  class="{{databaseStatus == 'UP' ? 'secondary-blue' : 'primary-danger'}}">{{ databaseStatus }}</span>
        </div>
        <div>IPFS Gateways:
            <span *ngIf="!loading"
                  class="{{ipfsGatewayStatus == 'UP' ? 'secondary-blue' : 'primary-danger'}}">{{ ipfsGatewayStatus }}</span>
        </div>
        <div>Services:
            <span *ngIf="!loading"
                  class="{{apiLiveness == 'UP' ? 'secondary-blue' : 'primary-danger'}}">{{ apiLiveness }}</span>
        </div>
        <div>Servers:
            <span *ngIf="!loading"
                  class="{{apiReadiness == 'UP' ? 'secondary-blue' : 'primary-danger'}}">{{ apiReadiness }}</span>
        </div>
    </div>
    <img class="mx-auto" alt="api" ngSrc="/api.png" height="300" width="300">
    <app-generic-button
            [ngClass]="'mt-3'"
            (click)="refreshApiStatus()"
            [loading]="loading"
            [buttonText]="'Refresh API status'"
            [primaryIcon]="'fa-solid fa-code'">
    </app-generic-button>
    <app-generic-button
            [ngClass]="'mt-3'"
            (click)="apiDocs()"
            [loading]="loading"
            [buttonText]="'To api documentation'"
            [primaryIcon]="'fa-solid fa-book'">
    </app-generic-button>
</div>
<app-error-modal
        [ngClass]="'mt-3'"
        [showModal]="apiStatus == 'DOWN'"
        [message]="'We seem to be having some trouble, please check again after a few minutes.'"
></app-error-modal>