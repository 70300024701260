import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as uuid from 'uuid';

import {HttpErrorResponse} from "@angular/common/http";

import {DataService} from "../../../services/data.service";
import {IpfsService} from "../../../services/api/ipfs.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {IdentityView} from "../../../services/model/identity-view";
import {ClaimView} from "../../../services/model/claim-reference-view";
import {SubscriptionView} from "../../../services/model/subscription-view";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {ExportModalComponent} from "../../common/export-modal/export-modal.component";
import {NewClaimModalComponent} from "../../common/new-claim-modal/new-claim-modal.component";
import {NewSubscriptionModalComponent} from "../../common/new-subscription-modal/new-subscription-modal.component";
import {ToVisibilityScopeIconPipe} from "../../common/pipes/ToVisibilityScopeIconPipe";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {ClaimsComponent} from "./claims/claims.component";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-identity',
  standalone: true,
  templateUrl: './identity.component.html',
  imports: [
    ActionButtonComponent,
    ExportModalComponent,
    NewClaimModalComponent,
    NewSubscriptionModalComponent,
    ToVisibilityScopeIconPipe,
    ToReadableStringPipe,
    ClaimsComponent,
    NgClass
  ],
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit, OnChanges {
  
  @Output() reloadCurrentIdentity: EventEmitter<any> = new EventEmitter();
  @Input() scopes: Array<string> = []
  @Input() identity!: IdentityView
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected shortSummary: string = ''
  protected identityIdentifier?: string = 'my-claims-reference-' + uuid.v4()
  protected newClaimIdentifier?: string = 'new-claim-' + uuid.v4()
  protected newRequestIdentifier?: string = 'new-subscription-' + uuid.v4()
  protected exportIdentifier?: string = 'export-' + uuid.v4()
  protected filePath: string = '';
  protected fileName: string = '';
  protected imageChangedEvent: any = '';
  protected base64Avatar: string = '';
  protected showEditAvatar: boolean = true;
  protected dataLoaded: boolean = false
  protected subscribedByCurrentIdentity: boolean = false
  
  constructor(protected dataService: DataService,
              private factsService: IpfsService,
              private cryptoWalletService: CryptoWalletService,
              private claimControllerService: ClaimControllerService) {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.getImage()
    if (this.identity?.introduction != undefined && this.identity?.introduction!.length > 50) {
      this.shortSummary = this.identity?.introduction?.substring(0, 50) + '...'
    } else {
      this.shortSummary = this.identity?.introduction || ''
    }
  }
  
  ngOnInit(): void {
    if (this.base64Avatar == undefined) {
      this.getImage()
    }
    // this.newClaimIdentifier = 'new-claim-' + this.identity!!.hash!!.toLowerCase()
    // this.newRequestIdentifier = 'new-subscription-' + this.identity!!.hash!!.toLowerCase()
    // this.exportIdentifier = 'export-' + this.identity!!.hash!!.toLowerCase()
    this.cryptoWalletService.getStored<string>('did').then(did => {
      this.subscribedByCurrentIdentity = this.identity.subscriptions!.some(subscription => subscription.subscriber == did)
    })
    this.dataService.last<Array<string>>('scopeAggregates').subscribe({
      next: scopes => {
        this.scopes = scopes
      }
    })
  }
  
  getImage() {
    if (!!this.identity?.avatarId) {
      this.loadingAvatar = true
      this.claimControllerService.viewClaims<Array<ClaimView>>(this.identity!.did!, this.identity?.avatarId, undefined)
        .subscribe({
          next: (response) => {
            const claim = response[0]
            if (!!claim.cid) {
              this.factsService.getFact(claim.cid).subscribe({
                next: fact => {
                  this.base64Avatar = fact?.value!
                  // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.base64Avatar);
                  this.base64ToFile()
                  this.loadingAvatar = false
                }
              })
            } else {
              this.loadingAvatar = false
            }
          },
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              this.errorOccurred = true
            }
          }
        });
      
    }
  }
  
  private base64ToFile() {
    const timestamp = new Date()
    const imageName = 'avatar-' + timestamp.toISOString() + '.jpg';
    const imageBlob = this.dataURItoBlob(this.base64Avatar!!);
    const newFile = new File([imageBlob], imageName, {type: 'image/*'});
    this.fileName = newFile.name
    this.filePath = URL.createObjectURL(newFile);
  }
  
  
  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], {type: 'image/jpeg'});
  }
  
  protected readonly sessionStorage = sessionStorage;
  
  @Input() index!: number;
  
  resolveClass() {
    return this.index % 2 == 0 ? 'btn-basic-secondary' : 'btn-basic-secondary-dark';
  }
  
  resolveSource() {
    return this.index % 2 == 0 ? "..//person-circle-light.svg" : "..//person-circle-dark.svg";
  }
  
  addSubscription($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity?.subscriptions?.push($event)
        this.identity = identity
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  addClaim($event: ClaimView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity?.claims?.push($event)
        this.identity = identity
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  noClaimsMade() {
    return this.identity?.claims?.length == 0;
  }
  
}
