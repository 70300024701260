import {Injectable} from '@angular/core';
import {IdentityControllerService} from "./api/identity-controller.service";
import {debounceTime, distinctUntilChanged, filter, Observable, ReplaySubject, Subject, take} from "rxjs";
import {MetadataControllerService} from "./api/metadata-controller.service";
import {CryptoWalletService} from "./crypto-wallet.service";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private dataStore: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private initialised: boolean = false
  private isLargeViewport: boolean = true;
  
  constructor(private cryptoWalletService: CryptoWalletService,
              private IdentityControllerService: IdentityControllerService,
              private metadataControllerService: MetadataControllerService) {
  }
  
  isLargeViewPort(): boolean {
    return this.isLargeViewport
  }
  
  checkViewportSize(): void {
    this.isLargeViewport = window.innerWidth >= 750;
  }
  
  
  continuousPublications<T>(key: string): Observable<T> {
    this.guard(key);
    return this.dataStore.get(key)!
      .asObservable()
      .pipe(filter(x => x !== undefined && x !== null))
  }
  
  continuousChanges<T>(key: string): Observable<T> {
    this.guard(key);
    return this.dataStore.get(key)!
      .asObservable()
      .pipe(filter(x => x !== undefined && x !== null))
      .pipe(distinctUntilChanged())
  }
  
  last<T>(key: string): Observable<T> {
    return this.continuousChanges<T>(key)
      .pipe(debounceTime(0), take(1))
  }
  
  publish<T>(key: string, value?: T): T | undefined {
    this.guard(key);
    this.dataStore.get(key)!.next(value);
    return value
  }
  
  
  has(name: string): boolean {
    return this.dataStore.has(name)
  }
  
  private guard(key: string) {
    if (!this.dataStore.has(key)) {
      this.dataStore.set(key, new ReplaySubject<any>(1))
    }
  }
  
  remove(name: string) {
    this.publish(name)
  }
  
  async init() {
    if (this.initialised) {
      return;
    }
    this.publish<boolean>('dataLoading', true)
    const did = await this.cryptoWalletService.getStored<string>('did')
    this.loadScopeMetadata();
    this.loadIdentity(did);
  }
  
  private loadIdentity(did: string) {
    this.IdentityControllerService.findIdentities(did!)
      .subscribe({
        next: async (identities) => {
          if (identities?.length == 1) {
            const identity = identities[0]
            await this.cryptoWalletService.store('salt', identity.salt)
            this.publish('identity', identity)
            this.initialised = true;
            this.publish('dataLoading', false)
          } else {
            throw Error(`Failed to load identity, too many matches for ${did}`);
          }
        }
      });
  }
  
  private loadScopeMetadata() {
    this.metadataControllerService.getClaimMetadata(undefined, 'all')
      .subscribe({
        next: (scopes) => {
          this.publish('allScopes', scopes)
        }
      });
    this.metadataControllerService.getClaimMetadata(undefined, "aggregates")
      .subscribe({
        next: (scopes) => {
          this.publish('scopeAggregates', scopes)
          this.publish('filteredScopeAggregates', scopes.filter((x, y, z) => !x.includes('profile')))
          // scopes.forEach(scope => this.loadClaimTypes(scope + 'claimTypes', scope))
        }
      });
  }
  
  private loadClaimTypes(key: string, scope: string) {
    // if (!this.has(key)) {
    //   this.metadataControllerService.getClaimMetadata(scope)
    //     .subscribe({
    //       next: (response) => {
    //         this.publish(key, response)
    //       }
    //     });
    // }
  }
  
  isInitialised(): boolean {
    return this.initialised
  }
  
  async destroy() {
    this.initialised = false
    await this.cryptoWalletService.removeStored('email')
    await this.cryptoWalletService.removeStored('did')
    await this.cryptoWalletService.removeStored('jwt')
    await this.cryptoWalletService.removeStored('identity')
  }
}