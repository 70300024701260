<div class="card p-2 mt-2 mb-3">
    <div class="container mt-3 max-height-400 overflow-y-scroll p-3">
        <h5 class="text-center mt-1">Subscriptions on my identity</h5>
        <div *ngFor="let subscription of identity?.subscriptions; index as i"
             class="container justify-content-center card p-3">
            <div class="row text-dark ">
                <div (click)="openOrClose(subscription.id!)"
                     [attr.aria-controls]="'subscription-'+subscription.id"
                     [attr.data-bs-target]="'#subscription-'+subscription.id"
                     aria-expanded="false"
                     data-bs-toggle="collapse"
                     class="p-0 m-0 pointer {{resolveClass(i, subscription)}}">
                    <div class="container d-flex align-items-center justify-content-between">

                            <span class=""><i class="ms-2 me-2 secondary-blue {{subscription | toStatusClass}} fa-xl"></i></span>
                            <span class="truncate-text">By {{ subscription.subscriber }}</span>

                        <app-action-button [showPrimary]="open.get(subscription.id!) || false"
                                           [showSecondary]="!open.get(subscription.id!) || false"
                                           [btnClass]="'btn-info'"
                                           [primaryIconClass]="'fa fa-chevron-up'"
                                           [secondaryIconClass]="'fa fa-chevron-down'">
                        </app-action-button>
                    </div>
                </div>
            </div>
            <div class="collapse" [id]="'subscription-'+subscription.id">
                <app-subscription [subscription]="subscription"
                                  (deleted)="delete($event)"
                                  (updated)="update($event)"
                                  [addressedToMe]="true"></app-subscription>
            </div>
        </div>
        <app-spinner [loading]="loadingSubscriptions"
                     [style]="'width: 40px; height: 40px;'"></app-spinner>
        <div class="mb-3" *ngIf="identity?.subscriptions?.length == 0">
            <div class="text-center greyed-out ">No subscriptions</div>
        </div>
    </div>
</div>