import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DevelopersComponent} from "./developers/developers.component";
import {AccountComponent} from "./account/account.component";

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        DevelopersComponent,
        AccountComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

    loading: boolean = false
    @Output() myAccountLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    errorOccurred: boolean = false
    errorMessage: string = ''

    constructor(protected activatedRoute: ActivatedRoute) {
    }
}
