import {Observable} from "rxjs";
import {AbstractHttpService} from "./abstract-http-service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MetadataControllerService extends AbstractHttpService {
  
  public getClaimMetadata(scopes?: string[], type?: string): Observable<Array<string>> {
    let localVarHeaders = this.defaultHeaders;
    let localVarPath = `/identities/claims/metadata/`;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    if (scopes != null) {
      localVarHeaders = localVarHeaders.set('Accept', 'application/vnd.doatoa.claim-types+json');
      localVarPath = localVarPath + `?scopes=${scopes}`
    }
    
    if (type != null) {
      localVarHeaders = localVarHeaders.set('Accept', 'application/vnd.doatoa.scopes+json');
      localVarPath = localVarPath + `?type=${type}`;
    }
    return this.get<Array<string>>(`${this.configuration.basePath}${localVarPath}`, localVarHeaders)
  }
  
}