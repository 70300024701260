import {Injectable} from "@angular/core";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  
  constructor(private router: Router) {
  }
  
  redirectToSignInPage() {
    this.router.navigate(['/sign-in']);
  }
  
  redirectToGateway() {
    window.location.href = "https://doatoa.com"
  }
  
}
