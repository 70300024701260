<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
        <div class="mt-4">
            <app-logo
                    [classes]="'mx-auto d-block'"
                    [height]="'100'"
                    [image]="'/doatoa-logo-full.svg'"
                    [width]="'150'">
            </app-logo>
        </div>
        <div *ngIf="stepOne" class="col-auto text-center max-width-300 p-3">
            <h2 class="text-center">Provide recovery phrase</h2>
            <input type="text"
                   id="mnemonic-phrase"
                   autocapitalize="off"
                   autocomplete="off"
                   placeholder="Your 24 recovery words"
                   class="form-control rounded-5 height-40"
                   [disabled]="mnemonicParts.length == 25"
                   (input)="tryDivide()"
                   [(ngModel)]="mnemonicPhrase">
            <div class="mt-2">
                <app-generic-button
                        id="recover-keys"
                        (click)="recoverKeys('doatoa'!)"
                        [btnClass]="'btn-info'"
                        [disabled]="mnemonicParts.length != 25"
                        [loading]="loading"
                        [buttonText]="'Recover keys'"
                        [primaryIcon]="'fa-solid fa-key'">
                </app-generic-button>
<!--                <app-generic-button [buttonText]="'Next'"-->
<!--                                    [btnClass]="'btn-info'"-->
<!--                                    [primaryIcon]="'fa fa-angle-right'"-->
<!--                                    [disabled]="mnemonicPhrase == undefined || mnemonicPhrase == ''"-->
<!--                                    (click)="addMnemonic()"></app-generic-button>-->
            </div>
            <div class="mt-2">
                <app-generic-button [buttonText]="'Clear'"
                                    [btnClass]="'btn-info'"
                                    [primaryIcon]="'fa fa-recycle'"
                                    [disabled]="mnemonicPhrase == undefined || mnemonicPhrase == ''"
                                    (click)="mnemonicPhrase = undefined; mnemonicParts = []"></app-generic-button>
            </div>
            <div *ngIf="mnemonicParts.length > 0">
                <div class="row m-2">
                    <div *ngFor="let m of mnemonicParts; let i = index" class="col-6">
                        <div *ngIf="i + 1 <= 24">{{ i + 1 }} {{ m }}</div>
                    </div>
                </div>
            </div>
        </div>

<!--        <div *ngIf="stepTwo" class="col-auto text-center max-width-300 p-3">-->
<!--            <div class="m-4">-->
<!--                <h2>Authentication method</h2>-->
<!--                <div *ngFor="let method of methods" class="row">-->
<!--                    <input (change)="flipMethod(method)"-->
<!--                           [checked]="chosenMethod == method"-->
<!--                           class="rounded-5 form-check-input col-2"-->
<!--                           id="restoreAuthMethodSwitch-{{method}}"-->
<!--                           type="checkbox">-->
<!--                    <label class="col-10" for="restoreAuthMethodSwitch-{{method}}">{{ method }}-->
<!--                        <a href="https://doatoa.com/docs/auth-methods" target="_blank">Learn more</a>-->
<!--                    </label>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="mb-2">-->
<!--                <app-generic-button-->
<!--                        id="recover-keys"-->
<!--                        (click)="recoverKeys(chosenMethod!)"-->
<!--                        [btnClass]="'btn-info'"-->
<!--                        [disabled]="chosenMethod == undefined || mnemonicParts.length > 24"-->
<!--                        [loading]="loading"-->
<!--                        [buttonText]="'Recover keys'"-->
<!--                        [primaryIcon]="'fa-solid fa-key'">-->
<!--                </app-generic-button>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>

