import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges} from '@angular/core';
import * as uuid from "uuid";
import {DataService} from "../../../services/data.service";
import {ClaimView} from "../../../services/model/claim-reference-view";
import {IdentityView} from "../../../services/model/identity-view";
import {SubscriptionView} from "../../../services/model/subscription-view";
import {ExportModalComponent} from "../../common/export-modal/export-modal.component";
import {NewSubscriptionModalComponent} from "../../common/new-subscription-modal/new-subscription-modal.component";
import {NewClaimModalComponent} from "../../common/new-claim-modal/new-claim-modal.component";
import {DeleteClaimModalComponent} from "../../common/delete-claim-modal/delete-claim-modal.component";
import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";
import {GenericMessageComponent} from "../../common/generic-message/generic-message.component";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {ToVisibilityScopeIconPipe} from "../../common/pipes/ToVisibilityScopeIconPipe";
import {ClaimComponent} from "../../common/claim/claim.component";

@Component({
  selector: 'app-my-claims',
  templateUrl: './my-claims.component.html',
  standalone: true,
  imports: [
    ExportModalComponent,
    NewSubscriptionModalComponent,
    NewClaimModalComponent,
    DeleteClaimModalComponent,
    ErrorModalComponent,
    GenericMessageComponent,
    ActionButtonComponent,
    SpinnerComponent,
    NgForOf,
    NgClass,
    ToReadableStringPipe,
    ToVisibilityScopeIconPipe,
    ClaimComponent,
    NgIf
  ],
  styleUrls: ['./my-claims.component.scss']
})
export class MyClaimsComponent implements OnChanges {
  
  @Output() claimIdToFetchEmitter = new EventEmitter<string>()
  @Output() reloadProfileEmitter = new EventEmitter<Array<ClaimView>>()
  @Input() scopes: Array<string> = [];
  @Input() loadingInitialClaims: boolean = false;
  @Input() identity?: IdentityView;
  protected claims?: Array<ClaimView> = [];
  protected newRequestId?: string = 'new-subscription-' + uuid.v4()
  protected exportId?: string = 'export-' + uuid.v4()
  protected newClaimId?: string = 'new-claim-' + uuid.v4()
  protected deleteClaimClicked: boolean = false
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected claimType: string = ''
  protected claimToDelete: ClaimView = {aspects: []}
  protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
  protected errorMessage: string = ''
  protected scope?: string = undefined
  protected showMessage: boolean = false;
  protected message: string = '';
  protected readonly sessionStorage = sessionStorage;
  
  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              protected dataService: DataService) {
  }
  
  ngOnChanges(changes:SimpleChanges): void {
    const currentValue = changes['identity'].currentValue as IdentityView;
    if (currentValue) {
      this.claims = currentValue.claims;
    }
    // this.dataService.last<IdentityView>('claims').subscribe({
    //   next: claims => {
    //     this.claims = claims
    //     this.claims = this.claims.claims
    //   }
    // })
  }
  
  protected tryDeleteClaim(claim: ClaimView) {
    this.deleteClaimClicked = true
    this.claimToDelete = claim
  }
  
  protected claimIsMandatory(claim: ClaimView) {
    let isMandatory = false;
    for (const aspect of claim.aspects!) {
      if (aspect == "mandatory") {
        isMandatory = true
      }
    }
    return isMandatory;
  }
  
  protected openCloseClaim(claimView: ClaimView) {
    this.open.set(claimView.cid!, !this.open.get(claimView.cid!) || false)
    // if (!this.requestedClaims.has(claimReference.id!)) {
    //   this.loading.set(claimReference.id!, true)
    //   this.dataService.last<string>('did').subscribe({
    //     next: did => {
    //       this.claimControllerService.viewClaims<Array<ClaimView>>(did, claimReference.id!, undefined, undefined, undefined, undefined, 'application/vnd.doatoa.claims+json')
    //         .subscribe({
    //           next: (response) => {
    //             const claim = response[0]
    //             this.loading.set(claimReference.id!, false)
    //             this.requestedClaims = this.requestedClaims.set(claim.id!, claim)
    //           },
    //           error: (error) => {
    //             this.loading.set(claimReference.id!, false)
    //             this.errorOccurred = true
    //           }
    //         });
    //     }
    //   })
    //
    // }
  }
  
  protected isLoading(claimId: string): boolean {
    return this.loading.has(claimId) && this.loading.get(claimId)!
  }
  
  protected removeClaimById(deletedClaimId: any) {
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.claims?.forEach((element, index) => {
          if (identity?.claims![index].cid == deletedClaimId) identity?.claims?.splice(index, 1);
          this.message = 'Claim deleted!'
          this.showMessage = true
          this.identity = identity
          this.dataService.publish<IdentityView>('identity', identity)
        });
      }
    })
  }
  
  protected resolveClass(index: number, claim: ClaimView): string {
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected updateClaimClass($event: string) {
    document.getElementById('claim-row-' + $event)
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        const element = this.elementRef.nativeElement.querySelector('#claim-row-' + $event);
        const claimView = identity?.claims?.find((c, i) => c.cid == $event)
        const index = identity?.claims?.indexOf(claimView!)
        if (index == undefined)
          return
        this.renderer.addClass(element, index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary');
      }
    })
  }
  
  protected addSubscription($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.subscriptions!.push($event)
        this.dataService.publish<IdentityView>('identity', identity)
      }
    })
  }
  
}
