import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FormsModule} from "@angular/forms";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ErrorModalComponent} from "../error-modal/error-modal.component";
import {GenericMessageComponent} from "../generic-message/generic-message.component";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {DataService} from "../../../services/data.service";
import {CryptographyService} from "../../../services/cryptography.service";
import {SubscriptionView} from "../../../services/model/subscription-view";
import {Subscriptions} from "../../../services/model/subscription-payload";
import {SubscriptionControllerService} from "../../../services/api/subscription-controller.service";
import {MetadataControllerService} from "../../../services/api/metadata-controller.service";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import * as uuid from 'uuid';

@Component({
  selector: 'protected app-new-subscription-modal',
  templateUrl: './new-subscription-modal.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ToReadableStringPipe,
    NgIf,
    ErrorModalComponent,
    GenericMessageComponent,
    GenericButtonComponent,
    ScrollingModule,
    NgClass,
    NgForOf
  ],
  styleUrls: ['./new-subscription-modal.component.scss']
})
export class NewSubscriptionModalComponent implements OnInit {
  
  @Output() submittedRequest: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  @Input() appClaimClass: string = ''
  @Input() title: string = ''
  @Input() customId?: string;
  @Input() subscriptionRequest?: SubscriptionView = undefined
  @Input() shareModal: boolean = false
  @Input() topicOwner?: string;
  @Input() subscriber?: string;
  protected scopes: Array<string> = []
  protected filteredScopes: Array<string> = []
  protected password?: string
  protected selectedClaimScopes: string[] = []
  protected errorOccurred: boolean = false
  protected showMessage: boolean = false
  protected loading: boolean = false
  protected success: boolean = false
  protected value?: string
  protected message?: string
  protected defaultScopeMessage: string = "Select a scope..."
  protected todayDate: Date = new Date()
  protected startingMoment?: string;
  protected duration: number = 1
  protected durationUnit: Subscriptions.DurationUnitEnum = Subscriptions.DurationUnitEnum.Years
  protected durations: Subscriptions.DurationUnitEnum[] = [
    Subscriptions.DurationUnitEnum.Hours,
    Subscriptions.DurationUnitEnum.Days,
    Subscriptions.DurationUnitEnum.Months,
    Subscriptions.DurationUnitEnum.Years
  ]
  protected description: string = ''
  protected DurationUnitEnum = Subscriptions.DurationUnitEnum;
  protected readonly Date = Date;
  protected readonly parent = parent;
  
  constructor(protected dataService: DataService, private cryptoWalletService: CryptoWalletService, private cryptographyService: CryptographyService, private requestCommandControllerService: SubscriptionControllerService, private metadataControllerService: MetadataControllerService) {
  }
  
  ngOnInit(): void {
    this.dataService.last<Array<string>>('allScopes').subscribe({
      next: scopes => {
        this.scopes = scopes.sort()
        this.filteredScopes = this.scopes
      }
    })
    if (!this.subscriber) {
      this.cryptoWalletService.getStored<string>('did').then(did => {
          this.subscriber = did
        }
      )
    }
  }
  
  async requestSubscription() {
    if (this.subscriber != undefined) {
      this.loading = true
      const rsaKeyPair = await this.cryptoWalletService.generateRSAKeyPair()
      const publicKey = rsaKeyPair.publicKey
      const privateKey = rsaKeyPair.privateKey
      const encryptedSecretSharingPrivateKey = await this.cryptoWalletService.encrypt(privateKey)
      this.requestCommandControllerService.requestSubscription({
          topicOwner: this.topicOwner!,
          subscriber: this.subscriber,
          scopes: this.selectedClaimScopes!,
          startingMoment: this.startingMoment,
          description: this.description,
          duration: this.duration,
          durationUnit: this.durationUnit,
          secretSharingPublicKey: publicKey,
          encryptedSecretSharingPrivateKey: encryptedSecretSharingPrivateKey
        }
      ).subscribe({
        next: (response) => {
          this.loading = false
          this.message = 'Successfully submitted the subscription'
          this.showMessage = true
          this.submittedRequest.emit(response)
        },
        error: (error) => {
          this.loading = false
          this.errorOccurred = true
        }
      });
    }
  }
  
  setUnit(event: any) {
    this.durationUnit = event.target.value as Subscriptions.DurationUnitEnum
  }
  
  setScope(scope: string) {
    this.selectedClaimScopes.push(scope)
  }
  
  filterScopes($event: any) {
    const searchedValue = $event.target.value;
    if (!searchedValue || searchedValue == "") {
      this.filteredScopes = this.scopes;
    } else {
      this.filteredScopes = this.scopes.filter((scope) =>
        scope.includes(searchedValue)
      )
    }
    
  }
}
