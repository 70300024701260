import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {DecisionPayload} from "../model/decision-payload-ns";
import {SubscriptionView} from "../model/subscription-view";
import {Subscriptions} from "../model/subscription-payload";
import {AbstractHttpService} from "./abstract-http-service";


@Injectable({
  providedIn: 'root'
})
export class SubscriptionControllerService extends AbstractHttpService {
  
  public requestSubscription(subscriptionPayload?: Subscriptions.SubscriptionPayload): Observable<SubscriptionView> {
    
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/subscriptions/`;
    return this.httpClient.request<SubscriptionView>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        body: subscriptionPayload,
        params: localVarQueryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders
      }
    );
  }
  
  
  public processSubscriptionRequest(decisionPayload: DecisionPayload): Observable<SubscriptionView> {
    
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/vnd.doatoa.decision+json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/subscriptions/`;
    return this.patch<SubscriptionView>(`${this.configuration.basePath}${localVarPath}`, decisionPayload, localVarHeaders)
  }
  
  
  public deleteSubscription(subscriptionId: string): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/subscriptions/`;
    return this.delete<any>(`${this.configuration.basePath}${localVarPath}`, {subscriptionId: subscriptionId}, localVarHeaders)
  }
  
  
}
