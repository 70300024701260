import {Component} from '@angular/core';
import {LogoComponent} from "../logo/logo.component";
import {ErrorModalComponent} from "../error-modal/error-modal.component";


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    ErrorModalComponent
  ],
  styleUrl: './error.component.scss'
})
export class ErrorComponent {

}
