<div class="container text-center pointer" (click)="copyToClipboard()">
    <span *ngIf="fact.value"
          class="secondary-blue">
        <img *ngIf="fact.contentType?.startsWith('image/')"
             style="{{style}}"
             class="p-3 user-select-all"
             src="data:{{fact.contentType}},{{fact.value}}"
             alt="fact-value"/>
        <div *ngIf="!fact.contentType?.startsWith('image/')" [id]="'fact-value-'+fact.value"
             class="pb-3 user-select-all">{{fact.value}}</div>
    </span>
    <div class="d-flex justify-content-center" *ngIf="clipboardService.showCopyConfirmation">
        <div class="text-center primary-blue" >(Copied to clipboard)</div>
    </div>
</div>