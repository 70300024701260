import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ApiStatusComponent} from "./api-status/api-status.component";
import {ErrorModalComponent} from "../../../common/error-modal/error-modal.component";

@Component({
  selector: 'app-developer-console',
  standalone: true,
  imports: [
    CommonModule,
    ApiStatusComponent,
    ErrorModalComponent
  ],
  templateUrl: './developer-console.component.html',
  styleUrl: './developer-console.component.scss'
})
export class DeveloperConsoleComponent {
  
  loading: boolean = false
  errorOccurred: boolean = false
  errorMessage: string = ''
  
}
