import {Component, OnInit} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgForOf, NgIf} from "@angular/common";
import {LogoComponent} from "../../common/logo/logo.component";
import {ErrorMessageService} from "../../../services/error-message-service";
import {DataService} from "../../../services/data.service";

import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";
// import TrezorConnect from "@trezor/connect-web";
import {DIDDocument, DIDDocumentPayload} from "../../../services/model/did-document";
import {environment} from "../../../environments/environment";
import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {IdentityPayload} from "../../../services/model/identity-payload";
import {oneHour} from "../../../app.config";
import {ClipboardService} from "../../../services/clipboard.service";


@Component({
  selector: 'app-mnemonic-phrase',
  standalone: true,
  imports: [
    GenericButtonComponent,
    NgForOf,
    NgIf,
    LogoComponent,
    ErrorModalComponent
  ],
  templateUrl: './mnemonic-phrase.component.html',
  styleUrl: './mnemonic-phrase.component.scss'
})
export class MnemonicPhraseComponent implements OnInit {
  
  protected termsAndConditionsAccepted: boolean = false
  protected loading: boolean = false;
  protected noEmail: boolean = false;
  protected errorOccurred: boolean = false
  protected authenticationMethod: string = 'doatoa';
  protected mnemonic: string = '';
  protected mnemonicParts: Array<string> = [];
  protected showCopyConfirmation: boolean = false;
  
  constructor(protected clipboardService: ClipboardService, private errorMessageService: ErrorMessageService, private dataService: DataService, private identityControllerService: IdentityControllerService, private cryptoWalletService: CryptoWalletService, private router: Router) {
  }
  
  ngOnInit(): void {
    // this.dataService.last<string>('authenticationMethod').subscribe({
    //   next: async authenticationMethod => {
    //     this.authenticationMethod = authenticationMethod;
    //     if (this.authenticationMethod == 'doatoa') {
    this.dataService.last<string>('mnemonic').subscribe({
      next: mnemonic => {
        this.mnemonic = mnemonic;
        this.mnemonicParts = mnemonic.split(" ")
      }
    })
    //     }
    //   }
    // })
  }
  
  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.mnemonic);
      this.showCopyConfirmation = true
      setTimeout(() => {
        this.showCopyConfirmation = false
      }, 1000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };
  
  switchTermsAndConditionsAccepted() {
    this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
  }
  
  async createIdentity() {
    this.loading = true
    const email = await this.cryptoWalletService.getStored<string>('email')
    const publicKey: string | undefined = await this.resolvePublicKey(this.authenticationMethod)
    if (!!publicKey) {
      const did: string = `did:key:${publicKey!}`
      this.cryptoWalletService.rollSalt().then(async salt => {
        await this.cryptoWalletService.store('did', did)
        let identityPayload: IdentityPayload = {
          did: did!,
          salt: salt,
          emailAddress: email,
          locale: navigator.languages[0]
        }
        this.setupJWT().then(jwt => {
          this.identityControllerService.register(identityPayload)
            .subscribe({
              next: async (response) => {
                await this.cryptoWalletService.removeStored('jwt')
                await this.router.navigate(['/sign-in'])
                this.loading = false
              },
              error: (error) => {
                this.loading = false
                this.errorMessageService.setMessage(error.message)
                this.router.navigate(['/sign-up'])
              }
            });
        })
      })
    } else {
      throw new Error(`No public key found for email: ${email}`)
    }
  }
  
  private createDIDDocumentPayload(did: string, publicKey: string): DIDDocumentPayload {
    return {
      context: "https://www.w3.org/ns/did/v1",
      assertionMethod: [did],
      authentication: [did + '#keys-1'],
      id: did,
      service: [],
      verificationMethod: [
        {
          id: did + '#keys-1',
          type: 'Ed25519VerificationKey2018',
          controller: did,
          publicKey: publicKey,
        }
      ]
    }
  }
  
  private createDIDDocument(didDocumentPayload: DIDDocumentPayload, signature: string): DIDDocument {
    return {
      context: didDocumentPayload.context,
      id: didDocumentPayload.id,
      controller: didDocumentPayload.id,
      verificationMethod: [{
        id: didDocumentPayload.verificationMethod[0].id,
        type: didDocumentPayload.verificationMethod[0].type,
        controller: didDocumentPayload.verificationMethod[0].controller,
        publicKey: didDocumentPayload.verificationMethod[0].publicKey
      }],
      authentication: didDocumentPayload.authentication,
      proof: {
        type: didDocumentPayload.verificationMethod[0].type,
        proofPurpose: 'assertionMethod',
        created: new Date().toISOString(),
        verificationMethod: didDocumentPayload.verificationMethod[0].id,
        signatureValue: signature
      }
    }
  }
  
  private async resolvePublicKey(method: string): Promise<string | undefined> {
    switch (method) {
      // case 'trezor': {
      //   const publicKeyRetrievalResponse = await TrezorConnect.getPublicKey({
      //     path: environment.cryptoPath
      //   })
      //   if (publicKeyRetrievalResponse.success) {
      //     return publicKeyRetrievalResponse.payload.publicKey
      //   }
      //   throw new Error(publicKeyRetrievalResponse.payload.error)
      // }
      case 'doatoa' :
        return this.cryptoWalletService.getSigningPublicKey()
      default:
        throw new Error(`Unknown method: ${method}`)
    }
  }
  
  private async setupJWT(): Promise<string> {
    console.log("Setting up JWT...");
    this.loading = true
    const doatoaDID = environment.did
    const did = await this.cryptoWalletService.getStored<string>('did')
    const header = {
      "alg": "EdDSA",
      "typ": "JWT"
    }
    const base64Header = btoa(JSON.stringify(header))
    const payload = {
      iss: did,
      aud: doatoaDID,
      exp: Date.now() + oneHour,
      att: [
        {
          with: environment.idpApiBaseUrl,
          can: "create-account",
        }
      ]
    }
    const base64Payload = btoa(JSON.stringify(payload))
    return await this.cryptoWalletService.sign(`${base64Header}.${base64Payload}`, 'doatoa').then(async signatureResult => {
      const signature = signatureResult.signature
      const completeJwt = `${base64Header}.${base64Payload}.${signature}`
      await this.cryptoWalletService.store('jwt', completeJwt)
      return completeJwt
    })
  }
}
