import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {IdentityView} from "../../../services/model/identity-view";
import {SubscriptionView} from "../../../services/model/subscription-view";
import {NgForOf, NgIf} from "@angular/common";
import {ToStatusClassPipe} from "../../common/pipes/ToStatusClassPipe";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {SubscriptionComponent} from "../../common/subscription/subscription.component";


@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  standalone: true,
  imports: [
    NgForOf,
    ToStatusClassPipe,
    ActionButtonComponent,
    SpinnerComponent,
    SubscriptionComponent,
    NgIf
  ],
  styleUrl: './my-subscriptions.component.scss'
})
export class MySubscriptionsComponent implements OnInit {
  @Input() loadingSubscriptions: boolean = false;
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected requestToEdit: SubscriptionView = {}
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected showMessage: boolean = false;
  protected message: string = '';
  protected identity?: IdentityView
  
  constructor(protected dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        this.identity = identity;
      }
    })
  }
  
  protected resolveClass(index: number, request: SubscriptionView): string {
    if (request.status == 'pending') {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected openOrClose(id: string) {
    this.open.set(id, !this.open.get(id) || false)
  }
  
  protected delete($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((element, index) => {
          if (identity!.subscriptions![index].id == $event.id) identity!.subscriptions!.splice(index, 1);
          this.message = 'Request deleted'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  protected update($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((element, index) => {
          if (identity!.subscriptions![index].id == $event.id) {
            identity!.subscriptions![index] = $event
          }
          this.message = 'Subscription updated'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataService.publish('identity', identity)
      }
    })
  }
}
