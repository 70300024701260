<div *ngIf="!loading">
    <div class="card p-3 mt-2 mb-3">
        <div class="" *ngFor="let notification of notifications">
            <div>{{ notification.actor }}</div>
            <div>{{ notification.timestamp }}</div>
            <div>{{ notification.type }}</div>
            <div>{{ notification.cid }}</div>
            <div>{{ notification.claimType }}</div>
        </div>
    </div>
</div>