import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../../services/data.service";
import {CryptographyService} from "../../../services/cryptography.service";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {EventComponent} from "../event/event.component";
import {ToReadableSubscriptionStatusPipe} from "../pipes/ToReadableRequestStatusPipe";
import {ClaimView} from "../../../services/model/claim-reference-view";
import {SubscriptionView} from 'src/app/services/model/subscription-view';
import {SubscriptionControllerService} from "../../../services/api/subscription-controller.service";
import {DecisionPayload, DecisionPayloadNs} from "../../../services/model/decision-payload-ns";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {ToStatusClassPipe} from "../pipes/ToStatusClassPipe";
import {IpfsService} from "../../../services/api/ipfs.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {IdentityView} from "../../../services/model/identity-view";
import {sha256} from "js-sha256";
import {ClaimPayload} from "../../../services/model/claim-payload";
import {VisibilityScopeEnum} from "../../../services/model/visibility-scope";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import DecisionEnum = DecisionPayloadNs.DecisionEnum;
import {MetadataControllerService} from "../../../services/api/metadata-controller.service";
import {MetadataView} from "../../../services/model/metadata-view";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToReadableStringPipe,
    NgForOf,
    NgIf,
    FormsModule,
    EventComponent,
    ToReadableSubscriptionStatusPipe,
    ToStatusClassPipe
  ],
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  
  protected loading: boolean = false
  protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
  protected reason?: string = undefined
  protected password?: string = undefined
  @Input() requestee: string = ''
  @Input() parent!: ClaimView;
  @Input() addressedToMe: boolean = false
  @Input() initiatedByMe: boolean = false
  @Input() subscription!: SubscriptionView
  protected metadataView?: MetadataView
  @Output() deleted: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  @Output() updated: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  errorOccurred: boolean = false
  
  constructor(private ipfsService: IpfsService,
              private dataService: DataService,
              private cryptoWalletService: CryptoWalletService,
              private cryptographyService: CryptographyService,
              private subscriptionCommandControllerService: SubscriptionControllerService,
              private claimControllerService: ClaimControllerService,
              private identityControllerService: IdentityControllerService) {
  }
  
  ngOnInit(): void {
    this.identityControllerService.findMetadata(this.subscription.subscriber).subscribe({
      next: metadataView => {
        this.metadataView = metadataView
      }
    })
  }
  
  //todo: move to pipe
  
  resolveIcon(status: string): string {
    switch (status) {
      case "created":
        return 'fa-solid fa-clock secondary-info'
      case "pending":
        return 'fa-solid fa-question-circle secondary-info'
      case "approved":
        return 'fa fa-check secondary-info'
      case "denied":
        return 'fa-solid fa-circle-xmark primary-danger'
      case "altered":
        return 'fa fa-question-circle secondary-info'
      case "exception":
        return 'fa fa-question-circle primary-danger'
      case "archived":
        return 'fa fa-archive greyed-out'
      default:
        return ''
    }
  }
  
  async processSubscription(decision: DecisionEnum) {
    if (this.requestIsOpen(this.subscription)) {
      this.loadingMap.set(decision, true)
      let myEncryptedAESPrivateKey = undefined
      const publicKeyString = this.subscription.secretSharingPublicKey!
      const providedRSAPublicKey = await this.cryptographyService.publicRSAStringToCryptoKey(publicKeyString)
      myEncryptedAESPrivateKey = await this.cryptoWalletService.encryptPrivateKey(providedRSAPublicKey)
      const decisionPayload: DecisionPayload = {
        subscriptionId: this.subscription.id!,
        reason: this.reason,
        decision: decision,
        encryptedEncryptionKey: decision == DecisionEnum.Approve ? myEncryptedAESPrivateKey : undefined
      }
      this.subscriptionCommandControllerService.processSubscriptionRequest(decisionPayload)
        .subscribe({
            next: (response) => {
              this.loadingMap.set(decision, false)
              this.subscription = response
              this.updated.emit(this.subscription)
            },
            error: (error) => {
              if (error instanceof HttpErrorResponse) {
                this.errorOccurred = true
              }
              this.loading = false
            }
          }
        )
    }
  }
  
  requestIsOpen(request: SubscriptionView): boolean {
    switch (request.status) {
      case "pending":
        return true
      case "approved":
        return false
      case "denied":
        return false
      default:
        return true
    }
  }
  
  deleteRequest() {
    this.loadingMap.set('delete', true)
    this.dataService.last<IdentityView>('identity').subscribe({
      next: (identity) => {
        identity.claims?.forEach(claim => {
          this.ipfsService.getFact(claim.cid).subscribe({
            next: async fact => {
              const decryptedValue = await this.cryptoWalletService.decrypt(fact.value!)
              this.cryptoWalletService.rollSalt().then(async salt => {
                this.identityControllerService.patchIdentity([
                  {
                    path: "/salt",
                    value: salt,
                    op: "replace"
                  }
                ]).subscribe({
                  next: async _ => {
                    const encryptedValue = await this.cryptoWalletService.encrypt(decryptedValue)
                    const signatureResult = await this.cryptoWalletService.sign(decryptedValue)
                    let claimToUpdate: ClaimPayload = {
                      encryptedFactValue: encryptedValue,
                      cidToReplace: claim.cid,
                      thumbprint: sha256.hex(decryptedValue),
                      claimType: claim.type,
                      claimVisibilityScope: VisibilityScopeEnum.Protected,
                      claimAspects: []
                    }
                    if (signatureResult) {
                      this.claimControllerService.makeClaim({
                        data: claimToUpdate,
                        signature: signatureResult.signature
                      }).subscribe({
                        next: (claimView) => {
                          const filteredClaims = identity.claims!.filter(c => c.cid == claim.cid)
                          filteredClaims.push(claimView)
                          identity.claims = filteredClaims
                          this.dataService.publish('identity', identity)
                        },
                      });
                    }
                  }
                })
              })
            }
          })
        })
        
        identity.subscriptions?.forEach(async subscription => {
          if (subscription.status === 'approved') {
            await this.processSubscription(DecisionEnum.Approve)
          }
        })
        
        this.subscriptionCommandControllerService.deleteSubscription(this.subscription.id!)
          .subscribe({
            next: (response) => {
              this.loadingMap.set('delete', false)
              this.deleted.emit(this.subscription)
            },
            error: (error) => {
              this.loading = false
              if (error instanceof HttpErrorResponse) {
                this.errorOccurred = true
              }
            }
          });
      }
    })
  }
  
}