<nav class="sticky-top navbar navbar-expand bg-white p-0" *ngIf="showMenu">
    <div class="container-fluid justify-content-center">
        <div class="container collapse navbar-collapse"
             id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto justify-content-center">
                <li class="btn min-width-100">
                    <a href="https://doatoa.com">
                    <app-logo class="col"
                              (click)="open = !open"
                              [classes]="'m-auto'"
                              [height]="'60'"
                              [image]="'/doatoa-logo-full.svg'"
                              [width]="'120'"></app-logo></a>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{dataLoading ? 'disabled' : ''}}"
                    routerLink="/settings"
                    routerLinkActive="active">
                    <i class="fa fa-gears"></i>
                    <div class="p-0">Settings</div>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{dataLoading ? 'disabled' : ''}}"
                    routerLink="/my-identity"
                    routerLinkActive="active">
                    <i class="fa fa-user"></i>
                    <div >My identity
                    </div>
                </li>
                <li ariaCurrentWhenActive='page'
                    class="btn min-width-100 nav-item pt-3 {{dataLoading ? 'disabled' : ''}}"
                    routerLink="/identities"
                    routerLinkActive="active">
                    <i class="fa fa-users"></i>
                    <div>Identities</div>
                </li>
                <li ariaCurrentWhenActive='page'
                    id="sign-out-menu-item"
                    class="btn min-width-100 nav-item pt-3"
                    routerLinkActive="active"
                    (click)="signOut()">
                    <i class="fa-solid fa-right-from-bracket"></i>
                    <div>Sign out</div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<hr class="mt-0">