<div class="min-width-300 overflow-x-hidden">
    <div class="container" *ngIf="loading">
        <div class="mt-4 row">
<!--            <div class="col-lg-3">-->
<!--                <div class="height-220 mb-4">-->
<!--                    <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-12">
                <div class="height-220 mb-4">
                    <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="height-220 mb-4">
                    <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="height-220 mb-4">
                    <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
                </div>
            </div>
        </div>
    </div>
    <div class="container flex-grow-1" *ngIf="!loading">
        <div class="row ">
<!--            <div class="col-lg-3">-->
<!--                <div class="card p-3 mt-2 g mb-3">-->
<!--                    <app-avatar [classList]="'d-flex justify-content-center align-items-center'"></app-avatar>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-12 mt-2">
                <app-identity-summary *ngIf="identity != undefined" [loading]="loading" [identity]="identity"></app-identity-summary>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-lg-12">
                <app-notifications></app-notifications>
            </div>
        </div>
        <div class="row">
            <app-my-claims
                    class="col-lg-6"
                    [identity]="identity"
                    [loadingInitialClaims]="loading"></app-my-claims>
            <app-my-subscriptions
                    class="col-lg-6"
                    [loadingSubscriptions]="loading"></app-my-subscriptions>
        </div>
    </div>
</div>
