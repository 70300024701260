<div class="card p-3 mb-4">
    <h3>Account</h3>
    <div class="mb-3"></div>
        <app-generic-button
                *ngIf="!dataService.isLargeViewPort()"
                id="my-account-sign-out-button"
                (click)="signOut()"
                [buttonText]="'Sign out'"
                [primaryIcon]="'fa-solid fa-right-from-bracket'">
        </app-generic-button>
        <hr class="mb-3"/>
    <app-generic-button routerLink="/close-account"
                        [btnClass]="'btn-danger'"
                        [buttonText]="'Delete account...'"
                        [primaryIcon]="'fa-regular fa-trash-can'">
    </app-generic-button>
</div>