import {DataService} from "../../services/data.service";
import {RedirectService} from "../../services/redirect-service";
import {Component} from "@angular/core";
import {SignOutService} from "../../services/signout-service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";

const nonAuthorizedPaths = ['sign-in', 'sign-up', 'error'];

@Component({template: ``})
export abstract class AuthorisedComponent {
  
  protected constructor(protected dataService: DataService, private signOutService: SignOutService, private cryptoWalletService: CryptoWalletService ) {
    console.log("Authorising access...")
    this.cryptoWalletService.hasStored('jwt').then(hasTwt => {
      if (hasTwt) {
        if (!dataService.isInitialised()) {
          this.dataService.init().then(() => {
            if (!nonAuthorizedPaths.some(path => window.location.href.includes(path))) {
            } else {
              this.dataService.publish('showMenu', true)
            }
          })
        } {
          this.dataService.publish('showMenu', true)
        }
      } else {
        this.signOutService.signOut()
      }
    })
  }
}