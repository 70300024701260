import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss'
})
export class MobileMenuComponent implements OnInit {
  
  @Input() showMenu: boolean = false
  protected logoutIsLoading: boolean = false
  protected open: boolean = false
  protected dataLoading: boolean = false
  constructor(private router: Router, protected dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.dataService.continuousChanges<boolean>('dataLoading').subscribe(dataLoading => {
      this.dataLoading = dataLoading;
    })
  }
  
  signOut() {
    this.logoutIsLoading = true
    this.router.navigate(['/sign-out'])
  }

}
