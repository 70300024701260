import {Component, Input, OnInit} from '@angular/core';
import {VisibilityScopeEnum} from "../../../services/model/visibility-scope";
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../../services/data.service";
import {CacheService} from "../../../services/cache-service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {sha256} from "js-sha256";
import {IdentityView} from "../../../services/model/identity-view";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {ClaimPayload} from "../../../services/model/claim-payload";
import {ClipboardService} from "../../../services/clipboard.service";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../../common/pipes/ToVisibilityScopeIconPipe";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../../common/pipes/ToOppositeClaimAspectIconPipe";
import {FormsModule} from "@angular/forms";
import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-identity-summary',
  templateUrl: './identity-summary.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToVisibilityScopeIconPipe,
    ToReadableStringPipe,
    ToOppositeVisibilityScopeIconPipe,
    FormsModule,
    ErrorModalComponent,
    NgIf,
    NgForOf
  ],
  styleUrl: './identity-summary.component.scss'
})
export class IdentitySummaryComponent {
  
  @Input() loading: boolean = false
  @Input() identity?: IdentityView
  protected loadingMap: Map<VisibilityScopeEnum, boolean> = new Map<VisibilityScopeEnum, boolean>()
  protected introductionUpdateLoading: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected introduction: string = ''
  protected maxCharacters: number = 100
  protected remainingCharacters: number = 100
  
  constructor(protected dataService: DataService, protected clipboardService: ClipboardService, private profileCommandControllerService: IdentityControllerService, private claimControllerService: ClaimControllerService, private cacheService: CacheService, private cryptoWalletService: CryptoWalletService) {
  }
  
  alterVisibility(visibilityScope: VisibilityScopeEnum) {
    this.loadingMap.set(visibilityScope, true)
    this.cacheService.bypassCache = true
    this.profileCommandControllerService.patchIdentity([{
      op: "replace",
      path: "/visibilityScope",
      value: visibilityScope.toLowerCase()
    }]).subscribe({
      next: (response) => {
        this.loadingMap.set(visibilityScope, false)
        this.dataService.last<IdentityView>('identity').subscribe({
          next: identity => {
            identity!.visibilityScope = visibilityScope
            this.dataService.publish('identity', identity)
            this.identity = identity
          }
        })
      },
      error: (error) => {
        this.loadingMap.set(visibilityScope, false)
        if (error instanceof HttpErrorResponse) {
          this.errorOccurred = true
        }
      }
    })
  }
  
  
  async submitIntroduction() {
    const claimPayload: ClaimPayload = {
      claimVisibilityScope: VisibilityScopeEnum.Protected,
      claimAspects: []
    }
    claimPayload.claimType = 'introduction'
    this.cacheService.bypassCache = true
    this.introductionUpdateLoading = true
    const encryptedValue = await this.cryptoWalletService.encrypt(this.introduction)
    const signatureResult = await this.cryptoWalletService.sign(encryptedValue)
    claimPayload.thumbprint = sha256.hex(this.introduction)
    claimPayload.encryptedFactValue = encryptedValue
    if (signatureResult) {
      this.claimControllerService.makeClaim({
        data: claimPayload,
        signature: signatureResult.signature
      }).subscribe({
        next: (ClaimView) => {
          this.dataService.last<IdentityView>('identity').subscribe({
            next: identity => {
              identity.claims?.push(ClaimView)
              this.dataService.publish('identity', identity)
              this.introductionUpdateLoading = false
            }
          })
        },
        error: (error) => {
          this.errorOccurred = true
          this.introductionUpdateLoading = false
        }
      });
    }
  }
  
  // submitIntroduction() {
  //   this.cacheService.bypassCache = true
  //   this.introductionUpdateLoading = true
  //   const factClaimPayload: ClaimPayload = {
  //     encryptedFactValue: this.introduction,
  //     claimType: "introduction",
  //     claimVisibilityScope: VisibilityScopeEnum.Public,
  //     claimAspects: []
  //   }
  //   this.cryptoWalletService.signData(factClaimPayload.encryptedFactValue!).then(signature => {
  //     if (signature) {
  //       this.claimControllerService.makeClaim({
  //         data: factClaimPayload,
  //         signature: signature.signature
  //       }).subscribe({
  //         next: (response) => {
  //           this.dataService.last<IdentityView>('claims').subscribe({
  //             next: claims => {
  //               claims!.claims?.push(response)
  //               claims!.introduction = this.introduction
  //               this.introductionUpdateLoading = false
  //               this.dataService.publish('claims', claims)
  //             }
  //           })
  //         },
  //         error: (error) => {
  //           this.introductionUpdateLoading = false
  //           if (error instanceof HttpErrorResponse) {
  //             this.errorOccurred = true
  //           }
  //         }
  //       })
  //     }
  //   })
  // }
  
  onIntroductionInput(event: any) {
    const enteredText = event.target.value;
    this.remainingCharacters = this.maxCharacters - enteredText.length;
  }
  
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  
}