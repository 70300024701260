import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ClaimView} from '../model/claim-reference-view';
import {AbstractHttpService} from "./abstract-http-service";
import {JsonPatchPayload} from "../model/json-patch-payload";
import {SignedData} from "../model/signed-data";
import {ClaimPayload} from "../model/claim-payload";
import {ClaimQueryPayload} from "../model/claim-query-payload";
import {ClaimSupport} from "../model/claim-support";

@Injectable({
  providedIn: 'root'
})
export class ClaimControllerService extends AbstractHttpService {
  
  public viewClaims<T>(owner: string, cid?: string, scope: string = "profile"): Observable<T> {
    
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/vnd.doatoa.claims+json');
    
    let localVarPath = `/identity/claims/`
    const scopes = scope !== undefined ? [scope] : [];
    const claimQueryPayload: ClaimQueryPayload = {
      owner: owner,
      scopes: scopes,
      cid: cid,
    }
    return this.post<T>(`${this.configuration.basePath}${localVarPath}`, claimQueryPayload, localVarHeaders)
  }
  
  public deleteClaim(claimId: string): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/claim/`;
    return this.delete(`${this.configuration.basePath}${localVarPath}`,
      {
        claimId: claimId
      },
      localVarHeaders)
  }
  
  public makeClaim(signedClaimPayloads: SignedData<ClaimPayload>): Observable<ClaimView> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    let localVarPath = `/identity/claim/`;
    return this.post<ClaimView>(`${this.configuration.basePath}${localVarPath}`, signedClaimPayloads, localVarHeaders)
  }
  
  public alterClaim(patchPayloads: Array<JsonPatchPayload>): Observable<ClaimView> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    let localVarPath = `/identity/claim/`;
    return this.patch<ClaimView>(`${this.configuration.basePath}${localVarPath}`,patchPayloads, localVarHeaders)
  }
  
  supportClaim(claimSupport: ClaimSupport): Observable<ClaimSupport> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    let localVarPath = `/identity/claims/support/`;
    return this.post<ClaimSupport>(`${this.configuration.basePath}${localVarPath}`,claimSupport, localVarHeaders)
  }
}
