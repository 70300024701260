import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {JsonPatchPayload} from '../model/json-patch-payload';
import {IdentityPayload} from "../model/identity-payload";
import {AbstractHttpService} from "./abstract-http-service";
import {IdentityView} from "../model/identity-view";
import {ActionView} from '../model/redirect-view';
import {MetadataView} from "../model/metadata-view";
import {IdentityQueryPayload} from "../model/identity-query-payload";


@Injectable({
  providedIn: 'root'
})
export class IdentityControllerService extends AbstractHttpService {
  
  public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json-patch+json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    if (token != undefined) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + token);
    }
    
    let localVarPath = `/identity/`;
    return this.patch<Array<JsonPatchPayload>>(`${this.configuration.basePath}${localVarPath}`, jsonPatchPayload, localVarHeaders)
  }
  
  
  public register(identityPayload: IdentityPayload): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/`;
    return this.post<IdentityPayload>(`${this.configuration.basePath}${localVarPath}`, identityPayload, localVarHeaders)
  }
  
  public closeAccount(did: string): Observable<any> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identities/`;
    return this.delete<any>(`${this.configuration.basePath}${localVarPath}`, {did: did}, localVarHeaders)
  }
  
  public findMetadata(did?: string, email?: string): Observable<MetadataView> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identities/metadata/`;
    return this.post<MetadataView>(`${this.configuration.basePath}${localVarPath}`, {did: did, email: email}, localVarHeaders)
  }
  
  public findIdentities(didHashAlias?: string, requestStatus?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<Array<IdentityView>> {

    let query: IdentityQueryPayload = {identifier: didHashAlias}
    if (requestStatus !== undefined && requestStatus != '') {
      query = {...query, requestStatus: requestStatus}
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      query = {...query, pageNumber: pageNumber}
    }
    if (pageSize !== undefined && pageSize !== null) {
      query = {...query, pageSize: pageSize}
    }
    if (sortBy !== undefined && sortBy !== null) {
      query = {...query, sortBy: sortBy}
    }

    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identities/`;
    return this.post<Array<IdentityView>>(`${this.configuration.basePath}${localVarPath}`, query, localVarHeaders)
  }
  
  startVerification(did: string, chosenMethod: string, scopes: string[]): Observable<ActionView<string>> {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/verification/`;
    return this.post<ActionView<string>>(`${this.configuration.basePath}${localVarPath}`, {
      did: did, locale: navigator.languages[0], verificationMethod: chosenMethod, scopes: scopes
    }, localVarHeaders)
    
  }
  
  registerAgent(did: string, token?: string, platform?: string, osVersion?: string, recipient?: string, type?: string) {
    let localVarHeaders = this.defaultHeaders;
    localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
    localVarHeaders = localVarHeaders.append('Accept', 'application/json')
    
    let localVarPath = `/identity/agent/`;
    return this.post(`${this.configuration.basePath}${localVarPath}`, {
      did: did,
      metadata: {
        type: type,
        notificationToken: token,
        platform: platform,
        osVersion: osVersion,
        recipient: recipient
      }
    }, localVarHeaders)
    
  }
}
