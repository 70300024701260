import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {GenericButtonComponent} from "../../../../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../../../../common/error-modal/error-modal.component";
import {CacheService} from "../../../../../services/cache-service";
import {IpfsService} from "../../../../../services/api/ipfs.service";
import {ApiStatusControllerService} from "../../../../../services/api/api-status-controller.service";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-api-status',
  standalone: true,
  imports: [CommonModule, GenericButtonComponent, ErrorModalComponent, NgOptimizedImage, GenericButtonComponent, ErrorModalComponent],
  templateUrl: './api-status.component.html',
  styleUrl: './api-status.component.scss'
})
export class ApiStatusComponent implements OnInit {
  loading: boolean = false
  apiStatus: string = ''
  databaseStatus: string = ''
  ipfsGatewayStatus: string = ''
  apiLiveness: string = ''
  apiReadiness: string = ''

  constructor(private cacheService: CacheService, private factsService: IpfsService, private apiStatusQueryControllerService: ApiStatusControllerService) {
  }

  ngOnInit(): void {
    this.refreshApiStatus()
  }

  refreshApiStatus() {
    this.loading = true
    this.cacheService.bypassCache = true
    this.factsService.getStatus().subscribe(status => {
      if (status.status.indicator == 'none') {
        this.ipfsGatewayStatus = 'UP'
      } else {
        this.ipfsGatewayStatus = 'DOWN'
      }
    })
    this.apiStatusQueryControllerService.checkApiStatus()
        .subscribe({
              next: value => {
                this.apiStatus = value?.status || "DOWN"
                this.databaseStatus = value?.components?.neo4j?.status || "DOWN"
                this.apiLiveness = value?.components?.livenessState?.status || "DOWN"
                this.apiReadiness = value?.components?.readinessState?.status || "DOWN"
                this.loading = false
              },
              error: value => {
                this.apiStatus = value?.error?.status || "DOWN"
                this.databaseStatus = value?.error?.components?.neo4j?.status || "DOWN"
                this.apiLiveness = value?.error?.components?.livenessState?.status || "DOWN"
                this.apiReadiness = value?.error?.components?.readinessState?.status || "DOWN"
                this.loading = false
              }
            }
        );
  }
  
  apiDocs() {
    window.location.href = environment.idpApiBaseUrl + '/public/api-docs';
  }
}
