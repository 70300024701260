import {ApplicationConfig, importProvidersFrom, inject, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withHashLocation} from '@angular/router';

import {routes} from './app.routes';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
  provideHttpClient,
  withInterceptors,
  withJsonpSupport
} from '@angular/common/http';
import {BrowserModule} from "@angular/platform-browser";
import {provideZxvbnServiceForPSM, ZxvbnConfigType} from "angular-password-strength-meter/zxcvbn";
import {DataService} from "./services/data.service";
import {ErrorMessageService} from "./services/error-message-service";
import {catchError, from, map, switchMap, take} from "rxjs";
import {RedirectService} from "./services/redirect-service";
import {translations} from '@zxcvbn-ts/language-en';
import {GetResult} from "@capacitor/preferences";
import {SignOutService} from "./services/signout-service";
import {CryptographyService} from "./services/cryptography.service";
import {CryptoWalletService} from "./services/crypto-wallet.service";

export const nonAuthenticatedPaths = ['.well-known', 'ipfs', 'pinata', 'metadata']

export const oneHour = 60 * 60 * 1000;
export const twentyFourHours = 24 * 60 * 60 * 1000;
export const threeDays = 3 * 24 * 60 * 60 * 1000;
export const sevenDays = 7 * 24 * 60 * 60 * 1000;
export const fourteenDays = 14 * 24 * 60 * 60 * 1000;
export const thirtyDays = 30 * 24 * 60 * 60 * 1000;
export const threeMonths = thirtyDays * 3;

export interface JWT {
  iss?: string;
  aud?: string;
  exp?: number;
  nbf?: number;
  att?: Attenuation[];
  prf?: string[];
}

export interface Attenuation {
  width?: string;
  can?: string;
}

export const ucanAuthenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const cryptoWalletService = inject(CryptoWalletService)
  const signOutService = inject(SignOutService)
  const shouldBeAuthenticated = nonAuthenticatedPaths.find(path => req.url.includes(path)) == undefined;
  if (shouldBeAuthenticated) {
    return from(cryptoWalletService.getStored<string>('jwt')).pipe(
      switchMap((jwt: string) => {
        if (jwt == null) {
          signOutService.signOut();
        }
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Basic ${jwt}`
          }
        });
        return next(clonedRequest);
      })
    )
  }
  return next(req)
}

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const dataService = inject(DataService);
  if (dataService.has('jwt') && nonAuthenticatedPaths.every(path => !req.url.includes(path))) {
    return dataService.last<string>('jwt').pipe(
      take(1),
      switchMap(token => {
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next(clonedRequest);
      })
    );
  }
  return next(req);
};

export const errorResponseInterceptor: HttpInterceptorFn = (req, next) => {
  const errorMessageService = inject(ErrorMessageService)
  const dataService = inject(DataService)
  const redirectService = inject(RedirectService)
  return next(req).pipe(
    map((event: any) => {
      if (event instanceof HttpResponse) {
        return event
      }
      return event
    }),
    catchError((response: any) => {
      if (response instanceof HttpErrorResponse) {
        if (response.status === 401) {
          dataService.remove('did');
          dataService.remove('email');
          dataService.remove('jwt');
          // dataService.remove('jwt');
          // dataService.remove('refreshToken');
          // dataService.remove('idToken')
          // dataService.remove('idTokenHint')
          redirectService.redirectToSignInPage()
        }
        if (response.status === 403) {
          errorMessageService.setMessage(response?.error?.message || "You are not allowed to take this action.")
        } else {
          errorMessageService.setMessage(response?.error?.message)
        }
      }
      throw response
    })
  );
};

const zxvbnConfig: ZxvbnConfigType = {
  translations: translations,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withJsonpSupport(), withInterceptors([ucanAuthenticationInterceptor, errorResponseInterceptor])),
    provideZoneChangeDetection({eventCoalescing: true}),
    importProvidersFrom([BrowserModule]),
    provideRouter(routes, withHashLocation()),
    provideZxvbnServiceForPSM(zxvbnConfig)
  ]
};
