export namespace Subscriptions {
    export interface SubscriptionPayload {
        topicOwner: string,
        subscriber?: string;
        scopes: Array<string>
        secretSharingPublicKey?: string;
        encryptedSecretSharingPrivateKey?: string;
        duration?: number;
        durationUnit?: Subscriptions.DurationUnitEnum;
        startingMoment?: string;
        description?: string;
    }

    export type DurationUnitEnum = 'minutes' | 'hours' | 'days' | 'months' | 'years';
    export const DurationUnitEnum = {
        Minutes: 'minutes' as DurationUnitEnum,
        Hours: 'hours' as DurationUnitEnum,
        Days: 'days' as DurationUnitEnum,
        Months: 'months' as DurationUnitEnum,
        Years: 'years' as DurationUnitEnum
    };
}


