import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {WebMenuComponent} from "./components/common/menu/web-menu.component";
import {RouterOutlet} from "@angular/router";
import {TextFieldModule} from "@angular/cdk/text-field";
import {RedirectService} from "./services/redirect-service";
import {AuthorisedComponent} from "./components/common/authorised-component";
import {DataService} from "./services/data.service";
import {MobileMenuComponent} from "./components/common/mobile-menu/mobile-menu.component";
import {NgIf} from "@angular/common";
import {SignOutService} from "./services/signout-service";
import {CryptoWalletService} from "./services/crypto-wallet.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    WebMenuComponent,
    TextFieldModule,
    RouterOutlet,
    MobileMenuComponent,
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends AuthorisedComponent implements OnInit {
  title = 'doatoa-resource-webapp';
  protected showMenu: boolean = false;
  protected publicFactsLoading: boolean = false;
  protected myAccountLoading: boolean = false;
  protected myProfileLoading: boolean = false;
  protected publicProfilesLoading: boolean = false;
  
  constructor(dataService: DataService, cryptoWalletService: CryptoWalletService, protected redirectService: RedirectService, signedOutService: SignOutService) {
    super(dataService, signedOutService, cryptoWalletService)
    this.dataService.checkViewportSize();
  }
  
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.dataService.checkViewportSize();
  }
  
  ngOnInit(): void {
    // this.ipfsService.publishToLocalNode()
    this.dataService.continuousChanges<boolean>('showMenu').subscribe(showMenu => {
      this.showMenu = showMenu;
    })
    
  }
  
  onActivate($event: Event) {
    if ('publicFactsLoading' in $event) {
      ($event as any).publicFactsLoading.subscribe((loading: boolean) => {
        this.publicFactsLoading = loading;
      });
    }
    if ('myAccountLoading' in $event) {
      ($event as any).myAccountLoading.subscribe((loading: boolean) => {
        this.myAccountLoading = loading;
      });
    }
    if ('myProfileLoading' in $event) {
      ($event as any).myProfileLoading.subscribe((loading: boolean) => {
        this.myProfileLoading = loading;
      });
    }
    if ('publicProfilesLoading' in $event) {
      ($event as any).publicProfilesLoading.subscribe((loading: boolean) => {
        this.publicProfilesLoading = loading;
      });
    }
  }
  
}
