import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {LogoComponent} from "../logo/logo.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-web-menu',
  standalone: true,
  templateUrl: './web-menu.component.html',
  imports: [
    LogoComponent,
    RouterLinkActive,
    RouterLink,
    NgIf
  ],
  styleUrls: ['./web-menu.component.scss']
})
export class WebMenuComponent implements OnInit {
  
  @Input() showMenu: boolean = false
  protected logoutIsLoading: boolean = false
  protected open: boolean = false
  protected dataLoading: boolean = true
  
  constructor(private router: Router, protected dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.dataService.continuousChanges<boolean>('dataLoading').subscribe(dataLoading => {
      this.dataLoading = dataLoading;
    })
  }
  
  signOut() {
    this.router.navigate(['/sign-out'])
  }
}
