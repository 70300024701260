// src/app/services/crypto.service.ts

import {Injectable} from '@angular/core';
// import TrezorConnect, {Success, Unsuccessful} from '@trezor/connect-web';
import {SigningResult} from "./model/signing-result";
import {CryptographyService} from "./cryptography.service";
import {wordlist} from "@scure/bip39/wordlists/english";
import * as bip39 from "@scure/bip39";
import {HDKey} from "@scure/bip32";
import {KeyStore} from "./key-store";
import {SecureStoragePlugin} from "capacitor-secure-storage-plugin";
import {GetResult, Preferences} from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class CryptoWalletService {
  private decoder: TextDecoder = new TextDecoder()
  
  constructor(private cryptographyService: CryptographyService) {
    // TrezorConnect.init({
    //   lazyLoad: false,
    //   manifest: {
    //     email: 'development@compilit.com',
    //     appUrl: 'https://app.doatoa.io',
    //   },
    // })
  }
  
  //todo: encrypt mnemonic phrase and aes with user password?
  async initialise(emailAddress: string, method: string, providedMnemonic?: string): Promise<string> {
    const mnemonic = providedMnemonic || bip39.generateMnemonic(wordlist, 256) //used for (HDKey and) AES private key deterministic derivation
    
    let base58CheckEncodedSigningPrivateKey = ''
    let base58CheckEncodedSigningPublicKey = ''
    
    if (method == 'doatoa') {
      //ECDSA
      
      const seed = await bip39.mnemonicToSeed(mnemonic);
      const masterKeypair = HDKey.fromMasterSeed(seed);
      const firstChild = masterKeypair.deriveChild(1)
      
      //encode for sharing purposes and for DID:key determination
      base58CheckEncodedSigningPrivateKey = await this.cryptographyService.baseEncode(firstChild.privateKey!, 'z')
      base58CheckEncodedSigningPublicKey = await this.cryptographyService.baseEncode(firstChild.publicKey!, 'z')
      
      //decode when received
      const rawSigningPrivateKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPrivateKey)
      const rawSigningPublicKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPublicKey)
      
      //actual signing test
      const message = "test"
      const signature = await this.cryptographyService.signMessage(message, rawSigningPrivateKey)
      const isValid = await this.cryptographyService.verifySignature(signature, rawSigningPublicKey, message)
      console.log(`Wallet successfully initialised: ${isValid}`)
    }
    //AES
    const encryptionArrayBuffer = await this.cryptographyService.deriveAESKeyFromMnemonic(mnemonic)
    const rootEncryptionKey = await this.cryptographyService.baseEncode(encryptionArrayBuffer)
    const salt = this.getHashedBase58EncodedSalt()
    // //RSA
    // const exchangeKeypair = await this.cryptographyService.generateRSAKeyPair()
    // const exchangeExportedKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.privateKey)
    // const exchangeExportedPublicKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.publicKey)
    // const exchangePrivateKey = await this.cryptographyService.baseEncode(exchangeExportedKeyBuffer)
    // const exchangePublicKey = await this.cryptographyService.baseEncode(exchangeExportedPublicKeyBuffer)
    
    const keyStore: KeyStore = {
      type: method,
      signingPrivateKey: base58CheckEncodedSigningPrivateKey,
      signingPublicKey: base58CheckEncodedSigningPublicKey,
      rootEncryptionKey: rootEncryptionKey
    }
    await this.store(emailAddress, JSON.stringify(keyStore), true)
    return mnemonic
  }
  
  async generateRSAKeyPair(): Promise<{ privateKey: string, publicKey: string }> {
    const exchangeKeypair = await this.cryptographyService.generateRSAKeyPair()
    const exchangeExportedKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.privateKey)
    const exchangeExportedPublicKeyBuffer = await crypto.subtle.exportKey('spki', exchangeKeypair.publicKey)
    const exchangePrivateKey = await this.cryptographyService.baseEncode(exchangeExportedKeyBuffer)
    const exchangePublicKey = await this.cryptographyService.baseEncode(exchangeExportedPublicKeyBuffer)
    
    return {
      privateKey: exchangePrivateKey,
      publicKey: exchangePublicKey
    }
  }
  
  async encrypt(data: string): Promise<string> {
    const privateKey = await this.getEncryptionKey()
    const cryptoKey = privateKey != undefined ? await this.cryptographyService.encryptionKeyToCryptKey(privateKey) : undefined
    if (cryptoKey == undefined) {
      throw new Error('encryptionPrivateKey')
    }
    return await this.encryptAES(data, cryptoKey)
  }
  
  async decrypt(data: string): Promise<string> {
    const privateKey = await this.getEncryptionKey()
    const cryptoKey = await this.cryptographyService.encryptionKeyToCryptKey(privateKey)
    return this.decryptAES(data, cryptoKey!)
  }
  
  // Function to encrypt a value using the RSA public key
  async encryptRSA(publicKey: CryptoKey, value: string): Promise<string> {
    const encoder = new TextEncoder();
    const encodedValue = encoder.encode(value);
    
    const encryptedBytes = await crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      publicKey,
      encodedValue
    );
    return this.cryptographyService.baseEncode(encryptedBytes)
  }
  
  async decryptRSA(cryptoKey: CryptoKey, encryptedValue: string): Promise<string> {
    const encodedValue = await this.cryptographyService.baseDecode(encryptedValue)
    const bytes = await crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      cryptoKey,
      encodedValue
    );
    return this.decoder.decode(bytes)
  }
  
  
  async sign(data: string, method?: string): Promise<SigningResult> {
    const preferredMethod = method || await this.getAuthenticationMethod()
    switch (preferredMethod) {
      case 'doatoa': {
        const signingKey = await this.getSigningPrivateKey()
        const rawSigningKey = signingKey != undefined ? await this.cryptographyService.baseDecode(signingKey) : undefined
        const signature = rawSigningKey != undefined ? await this.cryptographyService.signMessage(data, rawSigningKey) : undefined
        if (signature) {
          return new Promise((resolve, reject) => {
            resolve({
              address: "",
              signature: signature,
            })
          });
        } else {
          throw new Error('Unknown signature')
        }
      }
      default:
        throw new Error()
      // case 'trezor':
      //   return await TrezorConnect.signMessage({path: environment.cryptoPath, message: data});
      // default:
      //   return new Promise((resolve, reject) => {
      //     resolve({
      //       success: false,
      //       payload: {
      //         error: `Unknown method: ${method}`
      //       }
      //     })
      //   });
    }
    
  }
  
  // verify(signature: string, data: string) {
  //   this.dataService.last<string>('did').subscribe({
  //     next: did => {
  //       const publicKey = did.substring(7)
  //       TrezorConnect.verifyMessage({
  //         message: data,
  //         signature: signature,
  //         hex: false,
  //         coin: 'Ethereum', //todo: check
  //         address: this.publicKeyToAddress(publicKey, environment.cryptoNet)
  //       })
  //     }
  //   })
  // }
  
  async store<T>(key: string, value: T, secure: boolean = false): Promise<T> {
    if (secure) {
      await SecureStoragePlugin.set({
        key: key,
        value: JSON.stringify(value)
      })
    }
    await Preferences.set({key: key, value: JSON.stringify(value)})
    return Promise.resolve(value)
  }
  
  async getStored<T>(key: string, secure: boolean = false): Promise<T> {
    let getResult: GetResult
    if (secure) {
      getResult = await SecureStoragePlugin.get({key: key})
    } else {
      getResult = await Preferences.get({key: key})
    }
    if (getResult.value == undefined) {
      throw new Error(`Could not get stored stored value: ${key}`)
    }
    return JSON.parse(getResult.value!) as T
  }
  
  async hasStored(key: string, secure: boolean = false): Promise<boolean> {
    const keysResult = await Preferences.keys()
    const securedKeysResult = await SecureStoragePlugin.keys()
    return (!secure && keysResult.keys.includes(key)) || (secure && securedKeysResult.value?.includes(key))
  }
  
  async removeStored(key: string): Promise<void> {
    this.hasStored(key).then(async () => {
        await Preferences.remove({key: key})
      }
    )
    
    this.hasStored(key, true).then(async () => {
        await Preferences.remove({key: key})
      }
    )
  }
  
  async getKeyStore(email: string): Promise<KeyStore> {
    const keyStoreString = await this.getStored<string>(email, true)
    return JSON.parse(keyStoreString) as KeyStore
  }
  
  async getSigningPublicKey(): Promise<string> {
    const email = await this.getStored<string>('email')
    const keyStore = await this.getKeyStore(email)
    return keyStore?.signingPublicKey
  }
  
  async getAuthenticationMethod(): Promise<string> {
    return new Promise(async resolve => {
      const email = await this.getStored<string>('email')
      const keyStore = await this.getKeyStore(email)
      resolve(keyStore?.type)
    })
  }
  
  private async encryptAES(value: string, key: CryptoKey): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);
    const salt = this.getRawSalt(16); // Generate a random salt
    const iv = this.getRawSalt(12);
    ; // Generate a random IV
    const encryptedValue = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      data
    );
    
    const combinedArray = new Uint8Array(salt.length + iv.length + encryptedValue.byteLength);
    combinedArray.set(salt, 0);
    combinedArray.set(iv, salt.length);
    combinedArray.set(new Uint8Array(encryptedValue), salt.length + iv.length);
    return await this.cryptographyService.baseEncode(combinedArray)
  }
  
  private async decryptAES(encryptedValueWithSalt: string, key: CryptoKey): Promise<string> {
    const combinedArrayBuffer = await this.cryptographyService.baseDecode(encryptedValueWithSalt)
    const combinedArray = new Uint8Array(combinedArrayBuffer)
    const salt = combinedArray.slice(0, 16); // Extract the salt (first 16 bytes)
    const iv = combinedArray.slice(16, 28); // Extract the IV (next 12 bytes)
    const encryptedValue = combinedArray.slice(28); // Extract the encrypted value
    const decrypted = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      encryptedValue
    );
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  }
  
  private async getSigningPrivateKey(): Promise<string> {
    const email = await this.getStored<string>('email')
    const keyStore = await this.getKeyStore(email)
    return keyStore?.signingPrivateKey
  }
  
  private async getEncryptionKey(): Promise<string> {
    const salt = await this.getStored<string>('salt')
    const email = await this.getStored<string>('email')
    const keyStore = await this.getKeyStore(email)
    const rootEncryptionKey = keyStore?.rootEncryptionKey
    const encryptionArrayBuffer = await this.cryptographyService.deriveAESKeyFromSeed(rootEncryptionKey, salt)
    return await this.cryptographyService.baseEncode(encryptionArrayBuffer)
  }
  
  async encryptPrivateKey(providedRSAPublicKey: CryptoKey): Promise<string> {
    const myAESPrivateKey = await this.getEncryptionKey()
    return this.encryptRSA(providedRSAPublicKey, myAESPrivateKey)
  }
  
  async decryptRSAToAESToValue(encryptedValue: string | undefined, providedEncryptedAESPrivateKey: string, myEncryptedRSAPrivateKey: string): Promise<string | undefined> {
    if (!encryptedValue) {
      return encryptedValue
    }
    const myRSAPrivateKey = await this.decrypt(myEncryptedRSAPrivateKey)
    const myRSAKeyCryptoKey = await this.cryptographyService.privateRSAStringToCryptoKey(myRSAPrivateKey)
    const providedAESPrivateKey = await this.decryptRSA(myRSAKeyCryptoKey, providedEncryptedAESPrivateKey)
    const providedAESCryptoKey = await this.cryptographyService.encryptionKeyToCryptKey(providedAESPrivateKey)
    return await this.decryptAES(encryptedValue, providedAESCryptoKey)
  }
  
  async rollSalt(): Promise<string> {
    const newSalt = this.getHashedBase58EncodedSalt()
    await this.store<string>('salt', newSalt)
    return newSalt
  }
  
  private getRawSalt(length: number = 15): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(length));
  }
  
  private getHashedBase58EncodedSalt(length: number = 15): string {
    const salt = this.getRawSalt(length)
    const hash = this.cryptographyService.sha256(salt)
    return this.cryptographyService.base58Encode(hash);
  }
}