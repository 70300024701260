import {Injectable} from '@angular/core';
import {RedirectService} from "./redirect-service";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root'
})
export class SignOutService {
  
  constructor(private redirectService: RedirectService, private dataService: DataService) {
  }
  
  signOut() {
    this.dataService.publish('showMenu', false)
    this.dataService.destroy()
    this.redirectService.redirectToSignInPage()
  }
  
}
