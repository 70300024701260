import {Component} from '@angular/core';
import {SignOutService} from "../../../services/signout-service";
import {DataService} from "../../../services/data.service";
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";


@Component({
  selector: 'app-account',
  standalone: true,
  templateUrl: './account.component.html',
  imports: [
    GenericButtonComponent,
    NgIf,
    RouterLink
  ],
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  
  protected loading: boolean = false;
  protected patchLoading: boolean = false
  protected errorOccurred: boolean = false
  protected signOutLoading: boolean = false;
  
  constructor(private signOutService: SignOutService, protected dataService: DataService) {
  }
  
  signOut() {
    this.signOutService.signOut()
  }
}
