export interface DecisionPayload {
    subscriptionId: string,
    decision?: DecisionPayloadNs.DecisionEnum;
    reason?: string;
    encryptedEncryptionKey?: string;
}

export namespace DecisionPayloadNs {
    export type DecisionEnum = 'approve' | 'deny';
    export const DecisionEnum = {
        Approve: 'approve' as DecisionEnum,
        Deny: 'deny' as DecisionEnum
    };
}


