import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {DataService} from "../../services/data.service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {environment} from "../../environments/environment";
import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {NgClass, NgIf} from "@angular/common";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {oneHour} from "../../app.config";
import {PushNotifications, PushNotificationSchema} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import {IdentityControllerService} from "../../services/api/identity-controller.service";
import {SignOutService} from "../../services/signout-service";


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    GenericButtonComponent,
    ErrorModalComponent,
    NgIf,
    SpinnerComponent,
    NgClass
  ],
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  protected email?: string = undefined;
  protected code?: string = undefined;
  protected loading: boolean = false;
  protected showOptions: boolean = false;
  protected twoStepAuthenticationEnabled: boolean = false;
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected method: string = ''
  
  constructor(private signOutService: SignOutService, private router: Router, private dataService: DataService, private cryptoWalletService: CryptoWalletService, private identityCommandController: IdentityControllerService) {
  }
  
  ngOnInit(): void {
    this.cryptoWalletService.hasStored('email').then(hasEmail => {
      if (hasEmail) {
        this.cryptoWalletService.hasStored('jwt').then((hasJwt) => {
          if (hasJwt && this.dataService.has('identity')) {
            this.router.navigate(['/my-identity'])
          } else {
            this.loading = true;
            this.cryptoWalletService.getStored<string>('email').then(email => {
              this.createAuthenticationJWT(email)
            })
          }
        })
      } else {
        this.loading = false;
      }
    })
    
  }
  
  protected async process() {
    this.loading = true
    console.log("Checking JWT...");
    if (this.email == undefined) {
      throw Error('Please enter a valid email');
    }
    const email = await this.cryptoWalletService.store<string>('email', this.email!)
    this.createAuthenticationJWT(email)
  }
  
  isValidEmail = (email?: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  flipShowOptions() {
    this.showOptions = !this.showOptions
    setTimeout(() => {
      this.showOptions = !this.showOptions
    }, 3000)
    
  }
  
  async registerDeviceForPushNotifications() {
    console.log(`Registering device for notifications (${environment.platform})...`);
    if (environment.platform != 'web') {
      this.dataService.publish<Array<PushNotificationSchema>>('notifications', [])
      await registerNotifications()
      await PushNotifications.addListener('registration', async token => {
        const did = await this.cryptoWalletService.getStored<string>('did')
        Device.getInfo().then(device => {
          const platform = device.platform
          const osVersion = device.osVersion
          this.identityCommandController.registerAgent(did, token.value, platform, osVersion, "PushNotification").subscribe({
            next: () => {
              console.log("Native agent registered")
            }
          })
        })
      });
      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
      
      await PushNotifications.addListener('pushNotificationReceived', notification => {
        this.dataService.last<Array<PushNotificationSchema>>('notification').subscribe(currentNotifications => {
          currentNotifications.push(notification);
          this.dataService.publish<Array<PushNotificationSchema>>('notifications', currentNotifications)
        })
      });
    } else {
      const did = await this.cryptoWalletService.getStored<string>('did')
      const email = await this.cryptoWalletService.getStored<string>('email')
      this.identityCommandController.registerAgent(did, undefined, undefined, undefined, email, "EmailNotification").subscribe({
        next: () => {
          console.log("Web agent registered")
        }
      })
    }
  }
  
  
  private createAuthenticationJWT(email: string) {
    this.identityCommandController.findMetadata(undefined, email).subscribe({
      next: async metadataView => {
        const did = metadataView.did
        const hasKeystore = await this.cryptoWalletService.hasStored(email, true)
        if (did == undefined || !hasKeystore) {
          this.router.navigate(['/sign-up'])
        }
        if (did) {
          await this.cryptoWalletService.store('did', metadataView.did)
          console.log("Creating JWT...");
          const doatoaDID = environment.did
          const header = {
            "alg": "EdDSA",
            "typ": "JWT"
          }
          const base64Header = btoa(JSON.stringify(header))
          const payload = {
            iss: did,
            aud: doatoaDID,
            exp: Date.now() + oneHour,
            att: [
              {
                with: environment.idpApiBaseUrl,
                can: "read",
              },
              {
                with: environment.idpApiBaseUrl,
                can: "write",
              }
            ]
          }
          const base64Payload = btoa(JSON.stringify(payload))
          return await this.cryptoWalletService.sign(`${base64Header}.${base64Payload}`, 'doatoa').then(async signatureResult => {
            const signature = signatureResult.signature
            const completeJwt = `${base64Header}.${base64Payload}.${signature}`
            this.cryptoWalletService.store('jwt', completeJwt).then(() => {
              this.dataService.publish('showMenu', true)
              console.log("JWT created successfully.");
              this.registerDeviceForPushNotifications()
              this.router.navigate(['/my-identity'])
            })
          })
        } else {
          this.errorOccurred = true
          this.signOutService.signOut()
          this.errorMessage = "Something went wrong during authentication"
        }
      }
    })
  }
}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  
  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }
  
  await PushNotifications.register();
}

