import {HttpClient, HttpHeaders, HttpParameterCodec} from "@angular/common/http";
import {Configuration} from "../configuration";
import {Inject, Injectable, Optional} from "@angular/core";
import {BASE_PATH} from "../variables";
import {CustomHttpParameterCodec} from "../encoder";
import {environment} from "../../environments/environment";
import {from, Observable} from "rxjs";
import {CapacitorHttp, HttpOptions, HttpHeaders as NativeHeaders} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractHttpService {
  
  protected defaultHeaders = new HttpHeaders();
  protected configuration = new Configuration();
  protected encoder: HttpParameterCodec;
  protected basePath = environment.idpApiBaseUrl;
  
  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  
  get<T>(url: string, webHeaders?: HttpHeaders, nativeHeaders?: NativeHeaders): Observable<T> {
    if (environment.platform == 'web') {
      return this.httpClient.request<T>('get', url, {headers: webHeaders});
    }
    const options: HttpOptions = {
      url: url,
      headers: nativeHeaders!,
    };
    return from(CapacitorHttp.get(options).then(response => response.data));
  }
  
  post<T>(url: string, body: any, webHeaders?: HttpHeaders, nativeHeaders?: NativeHeaders): Observable<T> {
    if (environment.platform == 'web') {
      return this.httpClient.request<T>('post', url, {headers: webHeaders, body: body});
    }
    const options: HttpOptions = {
      url: url,
      data: body,
      headers: nativeHeaders!,
    };
    return from(CapacitorHttp.post(options).then(response => response.data));
  }
  
  put<T>(url: string, body: any, webHeaders?: HttpHeaders, nativeHeaders?: NativeHeaders): Observable<T> {
    if (environment.platform == 'web') {
      return this.httpClient.request<T>('put', url, {headers: webHeaders, body: body});
    }
    const options: HttpOptions = {
      url: url,
      data: body,
      headers: nativeHeaders!,
    };
    return from(CapacitorHttp.put(options).then(response => response.data));
  }
  
  patch<T>(url: string, body: any, webHeaders?: HttpHeaders, nativeHeaders?: NativeHeaders): Observable<T> {
    if (environment.platform == 'web') {
      return this.httpClient.request<T>('patch', url, {headers: webHeaders, body: body});
    }
    const options: HttpOptions = {
      url: url,
      data: body,
      headers: nativeHeaders!,
    };
    return from(CapacitorHttp.patch(options).then(response => response.data));
  }
  
  delete<T>(url: string, body: any, webHeaders?: HttpHeaders, nativeHeaders?: NativeHeaders): Observable<T> {
    if (environment.platform == 'web') {
      return this.httpClient.request<T>('delete', url, {body: body, headers: webHeaders});
    }
    const options: HttpOptions = {
      url: url,
      data: body,
      headers: nativeHeaders!,
    };
    return from(CapacitorHttp.delete(options).then(response => response.data));
  }
  
}